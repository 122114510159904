<i18n>
{
    "en": {
    "title": "Sensors have not detected any data.",
    "subtitle": "Wait until the sensors have processed at least one data acquisition. Refresh the page and try again."
  },
  "it": {
    "title": "Al momento, i sensori non hanno ancora rilevato nessun dato.",
    "subtitle": "Attendi fino a quando i sensori non avranno effettuato almeno un’acquisizione. Aggiorna la pagina e riprova."
  }
}
</i18n>
<template>
  <move-card rounded class="mb16 text-align-center">
    <move-text class="pb4 mt16" h2 bold
      >{{$t('title')}}</move-text
    >
    <move-text class="pb64" secondary paragraph
      >{{$t('subtitle')}}</move-text
    >
  </move-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "no-data-received",
  methods: {
    ...mapActions({
      setPage: "railsV2/setPage",
    }),
    onClickAddNewSection(){
      this.resetTempSection();
      this.setPage('section-form')
    }
  },
};
</script>
<style scoped></style>
