<template>
  <div class="mx16 main-container">
    <LoadingLogo v-if="getIsLoading" :soft="true"></LoadingLogo>
    <RailsHome v-if="getPage.path === 'main'" />
    <RailsSectionForm v-else-if="getPage.path === 'section-form'" />
    <RailsSectionResume v-else-if="getPage.path === 'section-resume'" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import RailsHome from "./pages/RailsHome.vue";
import RailsSectionForm from "./pages/RailsSectionForm.vue";
import RailsSectionResume from "./pages/RailsSectionResume.vue";
import LoadingLogo from "../../ui/LoadingLogo.vue"
export default {
  name: "rails",
  components: {
    RailsHome,
    RailsSectionForm,
    RailsSectionResume,
    LoadingLogo
  },
  computed: {
    ...mapGetters({
      getPage: "railsV2/getPage",
      getIsLoading: "railsV2/getIsLoading",
    }),
  },
  methods: {
    ...mapActions({
      restoreDefaultPage: "railsV2/restoreDefaultPage",
    }),
  },
  mounted() {
    this.restoreDefaultPage();
  },
};
</script>
<style scoped>
.main-container {
  overflow-x: auto;
}
</style>
