<i18n>
{
  "en": {
    "twistSpaceDomain": {
      "title": "Twist along the tracks",
      "subtitle": "Twist trend along the tracks.",
      "samples": {
        "title":"List of Acquisitions",
        "date":"Date range"
      },
      "infoTitle":{
        "title":"Twist along the tracks:",
        "content":{
          "1":"The Twist is the difference between two transversal levels measured separately at a predefined distance. To calculate Twist it is necessary to process the data collected by 2 consecutive Tiltmeters on the sleepers.",
          "2":"The graph shows the Twist trend along the tracks at a given moment. Each column of the graph corresponds to the Twist provided by a pair of sensors. The Twist is calculated using the data supplied simultaneously by all the sensors belonging to the Section"
        }
      },
      "infoSamples":{
        "title":"List of aquisitions:",
        "content":{
          "1":"Tiltmeters provide sampling with a certain cadence. All the sensors are synchronized and sample at the same time. Every item in the list represents a certain synchronized acquisition of all the sensors belonging to that Section.",
          "2":"Depending on the amplitude of the selected Date Range, aggregation criteria can vary with the purpose of keeping the list easy to read and understand."
        }
      },
      "graph": {
        "title": "Acquisition selected:",
        "y": "Twist",
        "x": "Distance"
      }
    },
    "twistTimeDomain": {
      "title": "Twist over time",
      "subtitle": "Twist trend over time of the selected sensor pair",
      "infoTitle":{
        "title":"Twist over time:",
        "content": "The graph shows the twist over time for a selected couple of consecutive sensors."
      },
      "graph": {
        "date": "Date range:",
        "select": "Selected Tiltmeter:",
        "y": "Twist",
        "x": "Time"
      }
    },
    "temperature": {
      "title": "Temperature trend",
      "subtitle": "The graph shows temperature over time for a selected sensor.",
      "infoTitle":{
        "title":"Temperature trend:",
        "content":"The graph shows temperature over time for a selected sensor"
      },
      "graph": {
        "y": "Temperature",
        "x": "Time"
      }
    }
  },
  "it": {
    "twistSpaceDomain": {
      "title": "Twist nello spazio",
      "subtitle": "Andamento del Twist lungo la linea ferroviaria.",
      "samples": {
        "title":"Elenco delle acquisizioni",
        "date":"Intervallo di date:"
      },
      "infoTitle":{
        "title":"Twist lungo il tracciato:",
        "content":{
          "1":"Il parametro del Twist / torsione dei binari corrisponde alla differenza algebrica tra due livelli trasversali misurati separatamente ad una distanza predefinita. Per calcolare il parametro Twist è necessario elaborare i dati raccolti da 2 inclinometri installati uno successivo all’altro sulle traversine.",
          "2":"Il grafico mostra l’andamento del Twist lungo il binario ad un determinato momento. Ogni colonna del grafico corrisponde al valore di Twist fornito da una coppia di sensori. I valori del Twist sono elaborati dai dati forniti contemporaneamente da tutti i sensori appartenenti alla Sezione di monitoraggio."
        }
      },
      "infoSamples":{
        "title":"Elenco delle acquisizioni",
        "content":{
            "1":"I sensori forniscono i valori con una frequenza pari alla loro cadenza di acquisizione. Tutti i sensori sono sincronizzati e rilevano contemporaneamente. Ogni voce dell’elenco corrisponde ad una determinato acquisizione sincronizzata di tutti i sensori che appartengono alla Sezione di monitoraggio.",
            "2":"In base all’ampiezza dell’intervallo di tempo selezionato, i criteri di aggregazione dei dati possono variare con lo scopo di mantenere l’elenco delle acquisizioni fruibile."
        }
      },
      "graph": {
        "title": "Acquisizione selezionata:",
        "y": "Twist",
        "x": "Distanza"
      }
    },
    "twistTimeDomain": {
      "title": "Twist nel tempo",
      "subtitle": "Andamento nel tempo del Twist relativo alla coppia di sensori selezionata.",
      "infoTitle":{
        "title":"Twist nel tempo",
        "content": "Il grafico mostra l’andamento nel tempo del Twist relativo alla coppia di sensori selezionati."
      },
      "graph": {
        "date": "Intervallo di date:",
        "select": "Coppia di Tiltmeters:",
        "y": "Twist",
        "x": "Tempo"
      }
    },
    "temperature": {
      "title": "Temperatura nel tempo",
      "subtitle": "Andamento nel tempo della temperatura relativa alla coppia di sensori selezionata.",
      "infoTitle":{
        "title":"Temperature nel tempo",
        "content":"Il grafico mostra l’andamento nel tempo della temperatura relativa al singolo sensore selezionato."
      },
      "graph": {
        "y": "Temperatura",
        "x": "Tempo"
      }
    }
  }
}
</i18n>
<template>
  <no-data-configured v-if="getShowNoDataConfigured"></no-data-configured>
  <no-data-received v-else-if="getShowNoDataReceived && !getIsLoading"></no-data-received>
  <div v-else>
    <!-- TWIST SPACE DOMAIN -->
    <!-- ----------------------------------------------------------------------------------------------- -->
    <div class="twist-space-domain mb32">
      <div class="d-flex align-items-center">
        <move-text h3 b class="mr8">{{ $t("twistSpaceDomain.title") }}</move-text>
        <!-- info -->
        <move-ballon arrow="left" width="600px">
          <template #header>
            <move-text p b class="mb8">{{ $t("twistSpaceDomain.infoTitle.title") }}</move-text>
          </template>
          <template #content>
            <move-text p class="mb16">{{
              $t("twistSpaceDomain.infoTitle.content.1")
            }}</move-text>
            <move-text p>{{ $t("twistSpaceDomain.infoTitle.content.2") }}</move-text>
          </template>
        </move-ballon>
      </div>

      <move-text paragraph secondary>{{ $t("twistSpaceDomain.subtitle") }}</move-text>
      <div class="data twist-space-domain mt32 scroll2">
        <div>
          <div class="d-flex align-items-center">
            <move-text p b class="mb16 ml4">{{
              $t("twistSpaceDomain.samples.title")
            }}</move-text>
            <!-- info samples -->
            <move-ballon arrow="left" width="600px" class="mb16 ml8">
              <template #header>
                <move-text p b class="mb8">{{
                  $t("twistSpaceDomain.infoSamples.title")
                }}</move-text>
              </template>
              <template #content>
                <move-text p class="mb16">{{
                  $t("twistSpaceDomain.infoSamples.content.1")
                }}</move-text>
                <move-text p>{{ $t("twistSpaceDomain.infoSamples.content.2") }}</move-text>
              </template>
            </move-ballon>
          </div>
          <samples-table
            :unit="`${'\u2030'}`"
            class="table"
            :samples="getTwistEvents"
            :value="getSelectedTwistEvent"
            @input="onSelectTwistEvent"
          >
            <template #header>
              <div class="d-flex no-wrap overflow-x">
                <move-text p> {{ $t("twistSpaceDomain.samples.date") }} </move-text>
                <move-text p b class="ml8"
                  >{{ formatDateVerbose(getDateRange[0]) }} -
                  {{ formatDateVerbose(getDateRange[1]) }}</move-text
                >
              </div>
            </template>
          </samples-table>
        </div>

        <div class="graph">
          <div class="d-flex">
            <move-text p class="ml32">
              {{ $t("twistSpaceDomain.graph.title") }}
            </move-text>

            <move-text p b class="ml8">{{
              formatDate(getSelectedTwistEvent.date)
            }}</move-text>
          </div>
          <move-graph
            :traces="getTwistSpaceDomainGraph.traces"
            :yTitle="getTwistSpaceDomainGraph.yTitle"
            :xTitle="getTwistSpaceDomainGraph.xTitle"
          >
          </move-graph>
        </div>
      </div>
    </div>

    <!-- TWIST TIME DOMAIN -->
    <!-- ----------------------------------------------------------------------------------------------- -->
    <div class="data twist-time-domain mb32 scroll2">
      <div class="d-flex align-items-center">
        <move-text h3 b class="mr8">{{ $t("twistTimeDomain.title") }}</move-text>
        <!-- info title -->
        <move-ballon arrow="left" width="600px">
          <template #header>
            <move-text p b class="mb8">{{ $t("twistTimeDomain.infoTitle.title") }}</move-text>
          </template>
          <template #content>
            <move-text p>{{
              $t("twistTimeDomain.infoTitle.content")
            }}</move-text>
          </template>
        </move-ballon>
      </div>

      <move-text paragraph secondary class="mb32">{{
        $t("twistTimeDomain.subtitle")
      }}</move-text>
      <div class="d-flex">
        <move-text p> {{ $t("twistTimeDomain.graph.date") }} </move-text>
        <move-text p b class="ml8"
          >{{ formatDateVerbose(getDateRange[0]) }} -
          {{ formatDateVerbose(getDateRange[1]) }}</move-text
        >
      </div>
      <div class="d-flex align-items-center">
        <move-text p class="mr8"> {{ $t("twistTimeDomain.graph.select") }} </move-text>
        <move-select
          :options="getTwistEUIOptions"
          :value="getSelectedTwistEUIOption"
          name="label"
          @input="onChangeEUI"
          class="w200"
        ></move-select>
      </div>
      <div class="data">
        <div class="graph">
          <move-graph
            :traces="getTwistTimeDomainGraph.traces"
            :yTitle="getTwistTimeDomainGraph.yTitle"
            :xTitle="getTwistTimeDomainGraph.xTitle"
          >
          </move-graph>
        </div>
      </div>
    </div>

    <!-- TEMPERATURES -->
    <!-- ----------------------------------------------------------------------------------------------- -->
    <div class="pb32">
      <div class="d-flex align-items-center">
        <move-text h3 b class="mr8">{{ $t("temperature.title") }}</move-text>
        <!-- info -->
        <move-ballon arrow="left" width="600px">
          <template #header>
            <move-text p b class="mb8">{{ $t("temperature.infoTitle.title") }}</move-text>
          </template>
          <template #content>
            <move-text p>{{ $t("temperature.infoTitle.content") }}</move-text>
          </template>
        </move-ballon>
      </div>

      <move-text paragraph secondary>{{ $t("temperature.subtitle") }}</move-text>
      <div class="data temperatures scroll2">
        <div class="graph">
          <move-graph
            :traces="getTwistTemperatureGraph.traces"
            :yTitle="getTwistTemperatureGraph.yTitle"
            :xTitle="getTwistTemperatureGraph.xTitle"
          >
          </move-graph>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SamplesTable from "../../SamplesTable.vue";
import NoDataConfigured from "./NoDataConfigured.vue";
import NoDataReceived from "./NoDataReceived.vue";
import { mapGetters, mapActions } from "vuex";
import { getShortEui, getSensorFromEui } from "../../../../../../../helpers/sensors";
import { formatDate, formatDateVerbose } from "../../../../../../../helpers/dates";
import { getSleepersEUIs } from "../../../../../../../helpers/rail";
export default {
  name: "twist-main",
  components: { SamplesTable, NoDataReceived, NoDataConfigured },

  /**************************************************************** ****************************************************************/
  /* COMPUTED */
  /**************************************************************** ****************************************************************/

  computed: {
    ...mapGetters({
      getTwistEvents: "railsV2/getTwistEvents",
      getSelectedTwistEvent: "railsV2/getSelectedTwistEvent",
      getTwistSpace: "railsV2/getTwistSpace",
      getTwistTimeDomain: "railsV2/getTwistTimeDomain",
      getSelectedSection: "railsV2/getSelectedSection",
      getDateRange: "railsV2/getDateRange",
      getTwistTemperatures: "railsV2/getTwistTemperatures",
      getTwistEUIOptions: "railsV2/getTwistEUIOptions",
      getSelectedTwistEUIOption: "railsV2/getSelectedTwistEUIOption",
      getIsLoading: "railsV2/getIsLoading",
    }),

    getShowNoDataConfigured() {
      return this.getSleepersEUIs(this.getSelectedSection.sleepers).length === 0;
    },
    getShowNoDataReceived() {
      return this.getTwistEvents.length === 0;
    },

    /* Graph twist, space domain  */
    /****************************************************************/
    getTwistSpaceDomainGraph() {
      return {
        traces: this.getTwistSpaceDomainTraces,
        yTitle: this.getTwistSpaceDomainGraphYtitle,
        xTitle: this.getTwistSpaceDomainGraphXtitle,
      };
    },
    getTwistSpaceDomainTraces() {
      return [
        {
          x: this.getTwistSpace.x,
          y: this.getTwistSpace.y,
          type: "bar",
          mode: "markers",
          hovertemplate:
            "<b>EUI: %{text}</b><br><br>" +
            "%{yaxis.title.text}: %{y:.0}<br>" +
            "%{xaxis.title.text}: %{x:.0}<br>" +
            "<extra></extra>",
          text: this.getTwistSpace.usedDevices.map(
            (v) => `${this.getShortEui(v[0])} - ${this.getShortEui(v[1])}`
          ),
        },
      ];
    },
    getTwistSpaceDomainGraphYtitle() {
      return `${this.$t("twistSpaceDomain.graph.y")} - ${"\u2030"}`;
    },
    getTwistSpaceDomainGraphXtitle() {
      return `${this.$t("twistSpaceDomain.graph.x")} - m`;
    },

    /* Graph twist, time domain  */
    /****************************************************************/
    getTwistTimeDomainGraph() {
      return {
        traces: this.getTwistTimeDomainTraces,
        yTitle: this.getTwistTimeDomainGraphYtitle,
        xTitle: this.getTwistTimeDomainGraphXtitle,
      };
    },
    getTwistTimeDomainTraces() {
      return [
        {
          x: this.getTwistTimeDomain.x,
          y: this.getTwistTimeDomain.y,
          type: "scatter",
        },
      ];
    },
    getTwistTimeDomainGraphYtitle() {
      return `${this.$t("twistTimeDomain.graph.y")} - ${"\u2030"}`;
    },
    getTwistTimeDomainGraphXtitle() {
      return `${this.$t("twistTimeDomain.graph.x")}`;
    },

    /* Graph temperatures  */
    /****************************************************************/
    getTwistTemperatureGraph() {
      return {
        traces: this.getTwistTemperatureTraces,
        yTitle: this.getTwistTemperaturesYtitle,
        xTitle: this.getTwistTemperaturesXtitle,
      };
    },

    getTwistTemperatureEUI(){
      if(this.getSelectedTwistEUIOption && this.getSelectedTwistEUIOption.devices && this.getSelectedTwistEUIOption.devices.length){
        return [this.getShortEui(this.getSelectedTwistEUIOption.devices[0]),this.getShortEui(this.getSelectedTwistEUIOption.devices[1])]
      }
      return [null, null]
    },

    getTwistTemperatureTraces() {
      return [
        {
          x: this.getTwistTemperatures[0].dates,
          y: this.getTwistTemperatures[0].temperature,
          type: "scatter",
          line: {
            color: "rgb(219, 64, 82)",
            width: 2,
          },
          name: this.getTwistTemperatureEUI[0],
        },
        {
          x: this.getTwistTemperatures[1].dates,
          y: this.getTwistTemperatures[1].temperature,
          type: "scatter",
          line: {
            color: "rgb(55, 128, 191)",
            width: 2,
          },
          name: this.getTwistTemperatureEUI[1],
        },
      ];
    },
    getTwistTemperaturesYtitle() {
      return `${this.$t("temperature.graph.y")} - C°`;
    },
    getTwistTemperaturesXtitle() {
      return `${this.$t("temperature.graph.x")}`;
    },
  },

  /**************************************************************** ****************************************************************/
  /* METHODS */
  /**************************************************************** ****************************************************************/

  methods: {
    ...mapActions({
      setSelectedTwistEUIOption: "railsV2/setSelectedTwistEUIOption",
      setSelectedTwistEvent: "railsV2/setSelectedTwistEvent",
      fetchTwistSpaceDomainData: "railsV2/fetchTwistSpaceDomainData",
      fetchTwistTimeDomain: "railsV2/fetchTwistTimeDomain",
      fetchTwistTemperatures: "railsV2/fetchTwistTemperatures",
      fetchAllTwistData: "railsV2/fetchAllTwistData",
      setLoading: "railsV2/setLoading",
    }),

    getShortEui: getShortEui,
    getSensorFromEui: getSensorFromEui,
    formatDate: formatDate,
    formatDateVerbose: formatDateVerbose,
    getSleepersEUIs: getSleepersEUIs,

    /****************************************************************/
    /* FETCH DATA WRAPPERS */
    /****************************************************************/

    /* Fetch all data */
    /****************************************************************/
    async fetchAllTwistDataWrapper() {
      await this.fetchAllTwistData({
        groupID: this.getSelectedSection._id,
        firstDate: this.getDateRange[0],
        lastDate: this.getDateRange[1],
      });
    },

    /* Fetch Twist - Time Domain */
    /****************************************************************/
    async fetchTwistTimeDomainDataWrapper(euis) {
      await this.fetchTwistTimeDomain({
        groupID: this.getSelectedSection._id,
        euis,
        firstDate: this.getDateRange[0],
        lastDate: this.getDateRange[1],
      });
    },

    /* Fetch Twist - Space Domain */
    /****************************************************************/
    async fetchTwistSpaceDomainDataWrapper(twistEvent) {
      await this.fetchTwistSpaceDomainData({ eventID: twistEvent._id });
    },

    /* Fetch Temperature */
    /****************************************************************/
    async fetchTwistTemperatureDataWrapper(devices) {
      await this.fetchTwistTemperatures({
        devices,
        firstDate: this.getDateRange[0],
        lastDate: this.getDateRange[1],
      });
    },

    /****************************************************************/
    /* COMPONENT ACTIONS */
    /****************************************************************/

    async onSelectTwistEvent(twistEvent) {
      this.setLoading(true);
      this.setSelectedTwistEvent(twistEvent);
      await this.fetchTwistSpaceDomainDataWrapper(twistEvent);

      const twistEUIOption = this.getTwistEUIOptions && this.getTwistEUIOptions[0]

      if (twistEUIOption) {
        this.setSelectedTwistEUIOption({twistEUIOption})
        await this.fetchTwistTimeDomainDataWrapper(twistEUIOption.devices);
        await this.fetchTwistTemperatureDataWrapper(twistEUIOption.devices);
      }

      this.setLoading(false);
    },

    async onChangeEUI(payload = { eui: null, label: null, devices: [null, null] }) {
      this.setLoading(true);
      this.setSelectedTwistEUIOption({twistEUIOption: payload})
      await this.fetchTwistTimeDomainDataWrapper(payload.devices);
      await this.fetchTwistTemperatureDataWrapper(payload.devices);
      this.setLoading(false);
    },
  },

  /**************************************************************** ****************************************************************/
  /* WATCHER */
  /**************************************************************** ****************************************************************/

  watch: {
    getDateRange: {
      handler: async function () {
        this.setLoading(true);
        await this.fetchAllTwistDataWrapper();
        this.setLoading(false);
      },
      deep: true,
    },
    getSelectedSection: {
      handler: async function () {
        this.setLoading(true);
        await this.fetchAllTwistDataWrapper();
        this.setLoading(false);
      },
      deep: true,
    },
  },

  /**************************************************************** ****************************************************************/
  /* LIFE CYCLE METHODS */
  /**************************************************************** ****************************************************************/

  async mounted() {
    this.setLoading(true);
    await this.fetchAllTwistDataWrapper();
    this.setLoading(false);
  },
};
</script>
<style scoped>
.data {
  display: grid;
  overflow-x: auto; 
}

.data.twist-space-domain {
  grid-template-columns: minmax(300px, 400px) minmax(600px, 1fr);
  column-gap: 32px;
}

.data.twist-time-domain {
  grid-template-columns: minmax(800px, 1fr);
}
.data.temperatures {
  grid-template-columns: minmax(800px, 1fr);
}

.twist-space-domain .data .table {
  height: 600px;
  max-height: 600px;
}


</style>
