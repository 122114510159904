<i18n>
{
  "en": {
    "title": "Tilt-beam chain name",
    "name": "Name",
    "hint": "Enter a unique name.",
    "btnCancel": "Back",
    "btnSave": "Save"
  },
  "it": {
    "title": "Nome catena Tilt-beam",
    "name": "Nome",
    "hint": "Inserisci un nome univoco",
    "btnCancel": "Annulla",
    "btnSave": "Salva"
  }
}
</i18n>

<template>
  <move-modal>
    <!-- HEADER -->
    <!-- ------------------------------------------------------------------------- -->
    <template #header>
      <move-text h2 class="mb32" :style="{ width: width }">{{ $t("title") }}</move-text>
    </template>
    <!-- CONTENT -->
    <!-- ------------------------------------------------------------------------- -->
    <div class="form mb32" :style="{ width: width }">
      <!-- Name -->
      <move-text p>{{ $t("name") }}</move-text>
      <move-input large class="mb16" v-model.trim="name" :valid="getValidationName">
        <template #hint>
          <move-text caption secondary>
            {{ $t("hint") }}
          </move-text>
        </template>
      </move-input>
    </div>

    <!-- FOOTER -->
    <!-- ------------------------------------------------------------------------- -->
    <template #footer :style="{ width: width }">
      <div class="text-align-right">
        <!-- Button cancel -->
        <move-btn class="mr8" secondary rounded outlined @click="$emit('cancel')">
          <move-text p bold>{{ $t("btnCancel") }}</move-text>
        </move-btn>
        <!-- Button save -->
        <move-btn
          :disabled="!getValidationSave"
          primary
          rounded
          outlined
          @click="$emit('save', name)"
        >
          <move-text p bold>{{ $t("btnSave") }}</move-text>
        </move-btn>
      </div>
    </template>
  </move-modal>
</template>
<script>
/**************************************************************** ****************************************************************/
/* IMPORTS */
/**************************************************************** ****************************************************************/
import _ from "lodash";

/**************************************************************** ****************************************************************/
/* COMPONENT */
/**************************************************************** ****************************************************************/
export default {
  name: "modal-beam-section",

  /**************************************************************** ****************************************************************/
  /* PROPS */
  /**************************************************************** ****************************************************************/
  props: {
    value: {
      type: String,
      default: "",
    },
    validationName: {
      type: Function,
      default: () => {
        return true;
      },
    },
    validationSave: {
      type: Function,
      default: () => {
        return true;
      },
    },
    width: {
      type: String,
      default: "100%",
    },
  },

  /**************************************************************** ****************************************************************/
  /* STATE */
  /**************************************************************** ****************************************************************/
  data() {
    return {
      name: _.cloneDeep(this.value),
    };
  },

  /**************************************************************** ****************************************************************/
  /* COMPUTED */
  /**************************************************************** ****************************************************************/

  computed: {
    getValidationName() {
      return this.validationName(this.name);
    },
    getValidationSave() {
      return this.validationSave(this.name);
    },
  },
};
</script>
<style scoped></style>
