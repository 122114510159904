<i18n>
{
  "en": {
    "name": "Name",
    "state": "Status",
    "stateSensors": "Online sensors",
    "direction": "Direction",
    "length": "Section Length",
    "enabled": "Active",
    "disabled": "Pause",
    "info": "About this section"
  },
  "it": {
    "name": "Nome",
    "state": "Stato",
    "direction": "Direzione",
    "stateSensors": "Sensori online",
    "length": "Lunghezza sezione",
    "enabled": "Attiva",
    "disabled": "In pausa",
    "info": "Informazioni su questo gruppo"
  }
}
</i18n>
<template>
  <div class="railway-details">
    <div class="col divider">
      <div class="item mb8">
        <move-text p secondary class="mr4">{{ $t('name') }}:</move-text>
        <move-text p> {{ section.name }} </move-text>
      </div>
      <div class="item mb8">
        <move-text p secondary class="mr4">{{ $t('state') }}:</move-text>
        <move-text p> {{ section.enabled ? $t('enabled') : $t('disabled') }} </move-text>
      </div>
      <div class="item mb8">
        <move-text p secondary class="mr4">{{ $t('stateSensors') }}:</move-text>
        <move-text p> {{ `${getOnlineSensors.length}/${sensors.length}` }} </move-text>
      </div>
      <div class="item mb8">
        <move-text p secondary class="mr4">{{ $t('direction') }}:</move-text>
        <move-text p> {{ section.direction[0] }} - {{ section.direction[1] }} </move-text>
      </div>
      <div class="item">
        <move-text p secondary class="mr4">{{ $t('length') }}:</move-text>
        <move-text p> {{ section.sectionLength }} m </move-text>
      </div>
    </div>
    <div class="col">
      <div>
        <move-text p secondary class="mr4">{{ $t('info') }}:</move-text>
        <move-text p> {{ section.notes }} </move-text>
      </div>
    </div>
  </div>
</template>
<script>
import {getSectionSensors} from "../../../../../helpers/rail"
export default {
  name: "railway-details",
  props: {
    section: {
      type: Object,
      default: () => {},
    },
    sensors: {
      type: Array,
      default: () => []
    }
  },
  computed:{
    getOnlineSensors() {
      return this.getSectionSensors(this.sensors, this.section)
        .filter(s => s.online)
    }
  },
  methods: {
    getSectionSensors: getSectionSensors
  },
};
</script>
<style scoped>

.item{
  display: flex;
  flex-wrap: wrap;
}
.railway-details {
  background: var(--secondary-color-dark);
  padding: 32px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 2fr 4fr;  
  column-gap: 16px;
}

.divider{
  border-right: 2px solid #505469
}
</style>
