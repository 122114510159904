<i18n>
{
  "en": {
    "modalBackButton":{
      "title": "Do you want to exit the page?",
      "msg": "You have unsaved changes. If you quit, changes will be lost.",
      "btnConfirmText": "Exit",
      "btnCancelText": "Stay on page"
    }
  },
  "it": {
    "modalBackButton":{
      "title": "Uscire da questa pagina?",
      "msg": "Hai modifiche non salvate. Se abbandoni, le modifiche andranno perse.",
      "btnConfirmText": "Esci",
      "btnCancelText": "Rimani sulla pagina"
    }
  }
}
</i18n>
<template>
  <div>
    <move-card rounded class="card-elem mx64">
      <form-template
        :step="getStep"
        :steps="getSteps"
        :isMinStep="getIsMinStep"
        :isMaxStep="getIsMaxStep"
        :hidePrevStep="getIsMinStep"
        :disableNextStep="!validateNextStep"
        :disableSave="!getValidationTempSection"
        @nextStep="incrementSectionStep"
        @prevStep="decrementSectionStep"
        @save="onSave"
        @back="onBack"
      >
        <form-step1 v-if="getStep === 1" class="my64"></form-step1>
        <form-step2 v-else-if="getStep === 2" class="my64"></form-step2>
        <form-step3 v-else-if="getStep === 3" class="my64"></form-step3>
      </form-template>
    </move-card>
    <ModalBack
      v-if="modalBackButton && getTempSectionIsChanged"
      @cancel="modalBackButton = false"
      @confirm="setPage('section-resume')"
      :title="$t('modalBackButton.title')"
      :msg="$t('modalBackButton.msg')"
      :btnConfirmText="$t('modalBackButton.btnConfirmText')"
      :btnCancelText="$t('modalBackButton.btnCancelText')"
    >
    </ModalBack>
  </div>
</template>
<script>
import FormTemplate from "./components/section-form/FormTemplate.vue";
import { mapActions, mapGetters } from "vuex";
import FormStep1 from "./components/section-form/FormStep1.vue";
import FormStep2 from "./components/section-form/FormStep2.vue";
import FormStep3 from "./components/section-form/FormStep3.vue";
import ModalBack from "./components/modals/ModalBack.vue"

export default {
  name: "rail-section",
  components: {
    FormTemplate,
    FormStep1,
    FormStep2,
    FormStep3,
    ModalBack
  },
  data() {
    return {
      modalBackButton: false
    };
  },
  computed: {
    ...mapGetters({
      getSteps: "railsV2/getSteps",
      getStep: "railsV2/getStep",
      getIsMinStep: "railsV2/getIsMinStep",
      getIsMaxStep: "railsV2/getIsMaxStep",
      getSelectedStructure: "admin/getSelectedStructure",

      getTempSectionIsChanged: "railsV2/getTempSectionIsChanged",

      getTempSection: "railsV2/getTempSection",

      getValidationTempSection: "railsV2/getValidationTempSection",

      getValidationTempSectionName: "railsV2/getValidationTempSectionName",
      getValidationTempSectionSleepersLength:
        "railsV2/getValidationTempSectionSleepersLength",
      getValidationTempSectionSectionLength:
        "railsV2/getValidationTempSectionSectionLength",
      getValidationTempSectionEndDirection:
        "railsV2/getValidationTempSectionEndDirection",
      getValidationTempSectionStartDirection:
        "railsV2/getValidationTempSectionStartDirection",
      getValidationTempSectionSleepers: "railsV2/getValidationTempSectionSleepers",
    }),
    getStructureID() {
      return this.getSelectedStructure && this.getSelectedStructure._id
        ? this.getSelectedStructure._id
        : null;
    },
    validateNextStep() {
      switch (this.getStep) {
        case 1:
          return (
            this.getValidationTempSectionName &&
            this.getValidationTempSectionSleepersLength &&
            this.getValidationTempSectionSectionLength &&
            this.getValidationTempSectionStartDirection &&
            this.getValidationTempSectionEndDirection
          );
        case 2:
          return this.getValidationTempSectionSleepers;
        default:
          return false;
      }
    },
  },
  methods: {
    ...mapActions({
      setPage: "railsV2/setPage",
      incrementSectionStep: "railsV2/incrementSectionStep",
      decrementSectionStep: "railsV2/decrementSectionStep",
      setSectionStep: "railsV2/setSectionStep",
      fetchSections: "railsV2/fetchSections",
      saveSection: "railsV2/saveSection",
      updateSection: "railsV2/updateSection",
      createNewSection: "railsV2/createNewSection",
      setLoading: "railsV2/setLoading",
    }),
    onBack() {
      if (this.getTempSectionIsChanged) {
        this.modalBackButton = true;
        return;
      }
      this.setPage('section-resume')
    },
    async onSave() {
      if (this.getTempSection._id) {
        this.setLoading(true);
        await this.updateSection({ section: this.getTempSection });
        this.setLoading(true);
      } else {
        this.setLoading(true);
        await this.createNewSection({ section: this.getTempSection });
        this.setLoading(false);
      }
      this.setPage("section-resume");
    },
  },
  async mounted() {
    this.setSectionStep(1);
    await this.fetchSections();
  },
};
</script>
<style scoped></style>
