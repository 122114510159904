<i18n>
{
   "en":{
      "title":"Sections",
      "subtitle":"Split the tracks into sections to better manage, analyze and understand the data. These setting options only apply to the rail tool. For Settings management, visit the Setting page.",
      "addSectionBtn":"Add new Section",
      "table":{
        "name":"SECTION NAME",
         "stateSection":"STATUS",
         "stateSensors":"ONLINE SENSORS",
         "direction":"DIRECTION",
         "totalLength":"SECTION LENGTH",
         "createdAt":"CREATION DATE",
         "sectionEnabled":"Active",
         "sectionDisabled":"Pause",
         "actions":"Actions",
         "actionsLabels":{
            "modify":"Modify",
            "delete":"Remove",
            "enable":"Active",
            "disable":"Pause"
         }
      },
      "modalDelete":{
         "title":"Do you want to delete the section",
         "msg":"You are deleting the Section. If you go on, all the data collected for that section will be lost. Before deleting a section, we suggest downloading the data.",
         "btnConfirmText":"Delete the Section",
         "btnCancelText":"Back"
      }
   },
   "it":{
      "title":"Sezioni di monitoraggio",
      "subtitle":"Suddividi la ferrovia in sezioni di monitoraggio per gestire, analizzare e comprendere meglio i dati correlati tra loro. Queste impostazioni si applicano solo al Tool Railway. Per la gestione dei parametri di funzionamento, vai alle Impostazioni.",
      "addSectionBtn":"Aggiungi sezione",
      "table":{
         "stateSection":"STATO",
         "stateSensors":"SENSORI ONLINE",
         "sectionEnabled":"Attivo",
         "sectionDisabled":"In pausa",
         "name":"NOME",
         "direction":"DIREZIONE",
         "createdAt":"DATA CREAZIONE",
         "totalLength":"LUNGHEZZA SEZIONE",
         "actions":"Actions",
         "actionsLabels":{
            "modify":"Modifica",
            "delete":"Rimuovi",
            "enable":"Attiva",
            "disable":"Metti in pausa"
         }
      },
      "modalDelete":{
         "title":"Eliminare la Sezione",
         "msg":"Stai eliminando una Sezione di monitoraggio. Se procedi, tutti i dati rilevati fino a questo momento non saranno più disponibili. Prima di eliminare una Sezione è consigliato scaricare tutti i dati di interesse.",
         "btnConfirmText":"Elimina sezione",
         "btnCancelText":"Annulla"
      }
   }
}
</i18n>
<template>
  <move-card rounded class="text-align-left">
    <div class="d-flex align-items-center">
      <move-btn icon class="mr8" @click="setPage('main')"
        ><font-awesome-icon icon="fa-solid fa-arrow-left" size="xl"
      /></move-btn>
      <move-text h2 bold>{{ $t("title") }}</move-text>
    </div>
    <move-text paragraph secondary>{{ $t("subtitle") }}</move-text>

    <div class="text-align-right mb8">
      <move-btn secondary outlined rounded large @click="onClickAddNewSection">
        <move-text p bold>
          <font-awesome-icon size="xl" icon="fa-solid fa-plus" />
          {{ $t("addSectionBtn") }}
        </move-text>
      </move-btn>
    </div>

    <div class="table-wrapper">
      <move-table
        maxHeight="200px"
        :orderActions="orderActions"
        :header="getTableHeader"
        :rows="getSections"
        :actions="getTableActions"
        :color="color"
        :mapFunction="onMapFunction"
        :value="getSelectedSection"
        @input="(value) => setSelectedSection(value)"
        @edit="onClickEdit"
        @delete="onClickDelete"
        @up="onClickUp"
        @down="onClickDown"
        @enable="onClickEnable"
        @disable="onClickDisable"
        class="table"
      >
      </move-table>
    </div>

    <railway-render
      class="mt32"
      :value="getSelectedSection"
      :sections="getSections"
      :customStyle="customStyle"
      @input="(value) => setSelectedSection(value)"
    ></railway-render>

    <!-- Modal delete section -->
    <!-- ------------------------------------------------------------------------- -->

    <ModalDelete
      v-if="deleteSectionModal"
      :title="`${$t('modalDelete.title')} ${tempSection.name} ?`"
      :msg="`${$t('modalDelete.msg')}`"
      :btnConfirmText="$t('modalDelete.btnConfirmText')"
      :btnCancelText="$t('modalDelete.btnCancelText')"
      @cancel="onCancelDeleteSection"
      @confirm="onConfirmDeleteSection"
      width="20vw"
      height="20vh"
    >
    </ModalDelete>
  </move-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RailwayRender from "./components/RailwayRender.vue";
import ModalDelete from "./components/modals/ModalDelete.vue";
import { formatDate } from "../../../../helpers/dates";
import { getSensorFromEui } from "../../../../helpers/sensors";
import { getSectionSensors, findSection } from "../../../../helpers/rail";

export default {
  name: "form-section-resume",
  components: { RailwayRender, ModalDelete },
  data() {
    return {
      tempSection: {},
      color: {
        header: "var(--secondary-color-light)",
        row: "transparent",
        rowHover: "var(--secondary-color-light-2)",
        rowClick: "var(--primary-color)",
      },
      colorRailway: {
        binary: "transparent",
        section: "transparent",
        sectionHover: "var(--secondary-color-light-2)",
        sectionClick: "var(--primary-color)",
        border: "var(--primary-border-color)",
      },
      customStyle: {
        binaryColor: "#ccc",
        sectionColor: "var(--secondary-color-light-2)",
        sectionHoverColor: "var(--secondary-color-light-2)",
        sectionClickColor: "var(--primary-color)",
        border: "2px solid var(--primary-border-color",
        sectionHeight: "100px",
        sectionWidth: "200px",
      },
      orderActions: true,
      deleteSectionModal: false,
      isInfoVisible: false,
      modalBackButton: false,
    };
  },
  computed: {
    ...mapGetters({
      getSections: "railsV2/getSections",
      getSelectedSection: "railsV2/getSelectedSection",
      getSensorsPlaced: "railsV2/getSensorsPlaced",
      getTotalSectionLength: "railsV2/getTotalSectionLength",
      getAllSensorsWithRailDetails: "railsV2/getAllSensorsWithRailDetails",
      getAvailableSection: "railsV2/getAvailableSection",
    }),
    getTableHeader() {
      return [
        {
          value: "name",
          label: this.$t("table.name"),
          length: "minmax(100px, 3fr)",
        },
        {
          value: "stateSection",
          label: this.$t("table.stateSection"),
          length: "minmax(200px, 200px)",
        },
        {
          value: "stateSensors",
          label: this.$t("table.stateSensors"),
          length: "minmax(200px, 200px)",
        },

        {
          value: "direction",
          label: this.$t("table.direction"),
          length: "minmax(100px, 2fr)",
        },
        {
          value: "length",
          label: this.$t("table.totalLength"),
          length: "minmax(100px,2fr)",
        },
        {
          value: "createdAt",
          label: this.$t("table.createdAt"),
          length: "minmax(100px, 2fr)",
        },
      ];
    },
    getTableActions() {
      return [
        {
          event: "enable",
          label: this.$t("table.actionsLabels.enable"),
          icon: "fa-check",
        },
        {
          event: "disable",
          label: this.$t("table.actionsLabels.disable"),
          icon: "fa-pause",
        },
        { event: "edit", label: this.$t("table.actionsLabels.modify"), icon: "fa-pen" },
        {
          event: "delete",
          label: this.$t("table.actionsLabels.delete"),
          icon: "fa-trash",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      setPage: "railsV2/setPage",
      fetchSections: "railsV2/fetchSections",
      editingSection: "railsV2/editingSection",
      deleteSection: "railsV2/deleteSection",
      resetTempSection: "railsV2/resetTempSection",
      setTempSection: "railsV2/setTempSection",
      decreaseOrder: "railsV2/decreaseOrder",
      increaseSectionOrder: "railsV2/increaseSectionOrder",
      decreaseSectionOrder: "railsV2/decreaseSectionOrder",
      setSelectedSection: "railsV2/setSelectedSection",
      selectAvailableSection: "railsV2/selectAvailableSection",
      updateSection: "railsV2/updateSection",
      setLoading: "railsV2/setLoading",
      setOldTempSection: "railsV2/setOldTempSection",
      getCleanTempSection: "railsV2/getCleanTempSection",
      resetOldTempSection: "railsV2/resetOldTempSection",
    }),

    formatDate: formatDate,
    getSensorFromEui: getSensorFromEui,
    getSectionSensors: getSectionSensors,
    findSection: findSection,

    onMapFunction(section) {
      return {
        _id: section._id,
        name: section.name,
        createdAt: formatDate(section.createdAt),
        length: `${section.sectionLength} m`,
        direction: `${section.direction[0]} ${"\u2192"} ${section.direction[1]}`,
        stateSection: section.enabled
          ? this.$t("table.sectionEnabled")
          : this.$t("table.sectionDisabled"),
        stateSensors: `${this.getOnlineSensors(section).length}/${
          this.getSectionSensors(this.getAllSensorsWithRailDetails, section).length
        }`,
      };
    },

    getOnlineSensors(section) {
      return this.getSectionSensors(this.getAllSensorsWithRailDetails, section).filter(
        (s) => s.online
      );
    },

    getSectionSensorsEui(section = {}) {
      const sleepersEUI = section.sleepers.map((s) => s.device.eui);
      const beams = section.beamSections.map((b) => b.beams).flat();
      const beamsEUI = beams.map((b) => b.device.eui);
      return [...sleepersEUI, ...beamsEUI];
    },

    onClickAddNewSection() {
      this.resetTempSection();
      this.resetOldTempSection();
      this.setPage("section-form");
    },
    onClickSections() {
      this.setPage("section-resume");
    },
    onClickEdit(payload = { index: -1, row: null }) {
      const section = this.getSections.find((s) => s._id === payload.row._id);
      this.setTempSection(section);
      this.setOldTempSection(section);
      this.setPage("section-form");
    },
    async onClickEnable(payload = { index: -1, row: null }) {
      const section = this.getSections.find((s) => s._id === payload.row._id);
      section.enabled = true;
      this.setLoading(true);
      await this.updateSection({ section });
      await this.fetchSections();
      this.setLoading(false);
    },
    async onClickDisable(payload) {
      const section = this.getSections.find((s) => s._id === payload.row._id);
      section.enabled = false;
      this.setLoading(true);
      await this.updateSection({ section });
      await this.fetchSections();
      this.setLoading(false);
    },
    async onClickDelete(payload = { index: -1, row: null }) {
      this.tempSection = payload.row;
      this.deleteSectionModal = true;
    },
    async onClickUp(payload = { index: -1, row: null }) {
      this.setLoading(true);
      await this.decreaseSectionOrder(payload.row._id);
      await this.fetchSections();
      this.setLoading(false);
    },
    async onClickDown(payload = { index: -1, row: null }) {
      this.setLoading(true);
      await this.increaseSectionOrder(payload.row._id);
      await this.fetchSections();
      this.setLoading(false);
    },

    /* MODAL DELETE SECTION - Cancel button  */
    /****************************************************************/
    async onCancelDeleteSection() {
      this.deleteSectionModal = false;
      this.tempSection = {};
    },

    /* MODAL DELETE SECTION - Confirm button  */
    /****************************************************************/
    async onConfirmDeleteSection() {
      this.setLoading(true);
      this.deleteSectionModal = false;
      await this.deleteSection(this.tempSection._id);
      await this.fetchSections();
      this.setSelectedSection(this.getAvailableSection);
      this.setLoading(false);
    },

    chooseRightSection() {
      const section = this.findSection(this.getSections, this.getSelectedSection._id);
      if (section) {
        this.setSelectedSection(section);
      } else {
        this.selectAvailableSection();
      }
    },
  },
  async mounted() {
    this.setLoading(true);
    await this.fetchSections();
    this.chooseRightSection();
    this.setLoading(false);
  },
};
</script>
<style scoped>
.table-wrapper{
  display: flex;
  overflow-x: auto;
  padding-bottom: 4px;

}
.table{
  min-width: 1200px;
  width: 100%;
  overflow-x: auto;
}
</style>
