<template>
  <move-modal>
    <!-- HEADER -->
    <!-- ------------------------------------------------------------------------- -->
    <template #header>
      <move-text h2 class="mb32" :style="{width: width}">{{ title }}</move-text>
    </template>

    <!-- CONTENT -->
    <!-- ------------------------------------------------------------------------- -->
    <div class="form mb32" :style="{width: width}">
      <move-text p>{{ msg }}</move-text>
    </div>

    <!-- FOOTER -->
    <!-- ------------------------------------------------------------------------- -->
    <template #footer :style="{width: width}">
      <div class="text-align-right">
        <!-- Button cancel -->
        <move-btn class="mr8" primary rounded outlined @click="$emit('cancel')">
          <move-text p bold>{{ btnCancelText }} </move-text>
        </move-btn>
        <!-- Button save -->
        <move-btn  secondary rounded outlined @click="$emit('confirm')">
          <move-text p bold>{{ btnConfirmText }}</move-text>
        </move-btn>
      </div>
    </template>
  </move-modal>
</template>
<script>
/**************************************************************** ****************************************************************/
/* COMPONENT */
/**************************************************************** ****************************************************************/
export default {
  name: "modal-delete",

  /**************************************************************** ****************************************************************/
  /* PROPS */
  /**************************************************************** ****************************************************************/
  props: {
    title: {
      type: String,
      default: "",
    },
    msg: {
      type: String,
      default: "",
    },
    btnConfirmText: {
      type: String,
      default: "Ok"
    },
    btnCancelText: {
      type: String,
      default: "Cancel"
    },
    width: {
      type: String,
      default: "100%"
    },
  },
};
</script>
<style scoped></style>
