<i18n>
{
  "en":{
    "title":"Add a sensor to the Tilt-beam chain",
    "status":{
        "title":"Sensor status",
        "online":"Online",
        "offline":"Offline"
    },
    "selection":{
        "title":"Select a sensor to add to the Tilt-beam Chain of the Section:",
        "placeholder":"-"
    },
    "distance":"Set the length of the beam:",
    "enabled":"Enabled",
    "disabled":"Disabled",
    "btnCancel":"Back",
    "btnSave":"Save"
  },
  "it":{
    "title":"Aggiungi un sensore alla catena di Tilt-beam",
    "status":{
        "title":"Stato sensore",
        "online":"Online",
        "offline":"Offline"
    },
    "selection":{
        "title":"Seleziona un sensore da aggiungere alla Catena di Tilt-beam della Sezione:",
        "placeholder":"-"
    },
    "distance":"Inserisci la lunghezza del beam:",
    "enabled":"Abilitato",
    "disabled":"Disabilitato",
    "btnCancel":"Annulla",
    "btnSave":"Salva"
  }
}
</i18n>

<template>
  <move-modal>
    <!-- HEADER -->
    <!-- ------------------------------------------------------------------------- -->
    <template #header>
      <move-text h2 class="mb32" :style="{width: width}" >{{ $t('title') }}</move-text>
    </template>

    <!-- CONTENT -->
    <!-- ------------------------------------------------------------------------- -->
    <div class="form mb32" :style="{width: width}">
      <!-- Sensor selection -->
      <move-text p class="max-width">{{ $t('selection.title') }}</move-text>
      <move-select
        class="mb8"
        :options="sensors"
        label="shortEui"
        v-model="beam.sensor"
      ></move-select>

      <!-- Sensor state -->
      <div class="d-flex align-items-center mb16">
        <move-text p>{{ $t('status.title') }}</move-text>
        <font-awesome-icon
          class="ml16"
          v-if="beam.sensor"
          icon="fa-solid fa-circle"
          size="2xs"
          :color="getColorSensorStatus(beam.sensor)"
        />
        <move-text p class="ml4">{{
          beam.sensor ? (beam.sensor.online ? "Online" : "Offline") : "-"
        }}</move-text>
      </div>

      <!-- Lunghezza -->
      <move-text p>{{ $t('distance') }}</move-text>
      <move-input
        type="number"
        large
        class="mb16"
        v-model.number="beam.length"
        :valid="getValidationLength"
        >m</move-input
      >

      <!-- Sensor disabled -->
      <div class="d-flex">
        <move-text class="mr8">{{ $t('enabled') }}:</move-text>
        <move-switch v-model="beam.enabled"></move-switch>
      </div>
    </div>

    <!-- FOOTER -->
    <!-- ------------------------------------------------------------------------- -->
    <template #footer :style="{width: width}">
      <div class="text-align-right">
        <!-- Button cancel -->
        <move-btn class="mr8" secondary rounded outlined @click="$emit('cancel')">
          <move-text p bold>{{ $t('btnCancel') }}</move-text>
        </move-btn>
        <!-- Button save -->
        <move-btn
          :disabled="!getValidationSave"
          primary
          rounded
          outlined
          @click="$emit('save', beam)"
        >
          <move-text p bold>{{ $t('btnSave') }}</move-text>
        </move-btn>
      </div>
    </template>
  </move-modal>
</template>
<script>
/**************************************************************** ****************************************************************/
/* IMPORTS */
/**************************************************************** ****************************************************************/
import _ from "lodash";

/**************************************************************** ****************************************************************/
/* COMPONENT */
/**************************************************************** ****************************************************************/
export default {
  name: "modal-beam",

  /**************************************************************** ****************************************************************/
  /* PROPS */
  /**************************************************************** ****************************************************************/
  props: {
    sensors: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({
        length: 0,
        enabled: true,
        sensor: null,
      }),
    },
    validationLength: {
      type: Function,
      default: () => {
        return true;
      },
    },
    validationSave: {
      type: Function,
      default: () => {
        return true;
      },
    },
    width: {
      type: String,
      default: "100%"
    },
  },

  /**************************************************************** ****************************************************************/
  /* STATE */
  /**************************************************************** ****************************************************************/
  data() {
    return {
      beam: _.cloneDeep(this.value),
    };
  },

  /**************************************************************** ****************************************************************/
  /* COMPUTED */
  /**************************************************************** ****************************************************************/
  computed: {
    getValidationLength() {
      return this.validationLength(this.beam.length);
    },
    getValidationSave() {
      return this.validationSave(this.beam);
    },
  },

  /**************************************************************** ****************************************************************/
  /* METHODS */
  /**************************************************************** ****************************************************************/

  methods: {
    getColorSensorStatus(sensor) {
      return sensor && sensor.online ? "green" : "red";
    },
  },
};
</script>
<style scoped>
.max-width{
  max-width: 400px;
}
</style>
