<i18n>
{
    "en": {
    "title": "Add sensors to view this parameter.",
    "subtitle": "Edit the section and add sensors to see the data",
    "addSectionBtn": "Edit the Section"
  },
  "it": {
    "title": "Non hai configurato questa Sezione.",
    "subtitle": "Modifica la Sezione e aggiungi dei sensori per poter visualizzare i dati.",
    "addSectionBtn": "Modifica la Sezione"
  }
}
</i18n>
<template>
  <move-card rounded class="mb16 text-align-center">
    <move-text class="pb4 mt16" h2 bold
      >{{$t('title')}}</move-text
    >
    <move-text class="pb64" secondary paragraph
      >{{$t('subtitle')}}</move-text
    >
    <move-btn primary rounded outlined class="mb32" @click="onClickAddNewSection">
      <move-text bold paragraph>{{$t('addSectionBtn')}}</move-text>
    </move-btn>
  </move-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "no-data-configured",
  computed: {
    ...mapGetters({
      getSelectedSection: "railsV2/getSelectedSection",
    })
  },
  methods: {
    ...mapActions({
      setPage: "railsV2/setPage",
      setTempSection: "railsV2/setTempSection",
    }),
    onClickAddNewSection(){
      this.setTempSection(this.getSelectedSection)
      this.setPage('section-form')
    }
  },
};
</script>
<style scoped></style>
