<i18n>
{
  "en":{
    "name":{
        "title":"Name of the Section",
        "subtitle":"Give the section a name.",
        "hint": "Enter a unique name."
    },
    "direction":{
        "title":"Direction of the railroad",
        "subtitle":"Set the two reference parameters that stand for the beginning and the end of the railroad.",
        "info":{
          "title":"Direction of the railroad:",
          "content":"The two reference parameters describe the direction the railroad is heading towards. They are used as a reference for the sensors’ placement."
        }
    },
    "description":{
        "title":"Description"
    },
    "sectionLength":{
        "title":"Lenght of the section",
        "hint":"Express a value with an accuracy of 1m"
    },
    "sleepersLength":{
        "title":"Distance between the rail tracks",
        "hint":"Express a value with an accuracy of 1m",
        "info":{
          "title":"Distance between the rails:",
          "content":"Setting the distance between the rails is necessary for a proper Cant and Twist estimation."
        }
    }
  },
  "it":{
    "name":{
        "title":"Nome della sezione",
        "subtitle":"Imposta un titolo identificativo del tratto ferroviario.",
        "placeholder":"Assegna un nome alla sezione",
        "hint": "Inserisci un nome univoco"
    },
    "direction":{
        "title":"Direzione ferrovia",
        "subtitle":"Indica due parametri di riferimento per indicare l’inizio e la fine del tratto ferroviario.",
        "info":{
          "title":"Direzione della ferrovia:",
          "content":"I parametri di riferimento di inizio e fine della Sezione indicano la direzione della ferrovia e servono come convenzione per impostare correttamente l’ordine dei sensori."
        }
    },
    "description":{
        "title":"Descrizione",
        "hint":"Minimo 1 carattere, massimo 96 caratteri"
    },
    "sectionLength":{
        "title":"Lunghezza linea ferroviaria",
        "hint":"Esprimi un valore con accuratezza pari a 1m"
    },
    "sleepersLength":{
        "title":"Distanza tra i binari",
        "hint":"Esprimi un valore con accuratezza pari a 1m pari",
        "info":{
          "title":"Distanza tra i binari:",
          "content":"Impostare correttamente la distanza tra i binari è fondamentale per calcolare correttamente i valori di Cant e Twist."
        }
    }
  }
}
</i18n>
<template>
  <div>
    <!-- ------------------------------------------------------------------------- -->
    <!-- Nome della sezione -->
    <!-- ------------------------------------------------------------------------- -->
    <move-text h3 bold>{{ $t("name.title") }}</move-text>
    <move-text paragraph secondary class="mb16">{{ $t("name.subtitle") }}</move-text>
    <move-input
      large
      class="input-width-large mb32"
      :valid="getValidationTempSectionName"
      :value="getTempSection.name"
      @input="onChangeSectionName"
    >
      <move-text v-if="getIsAsyncValidationOnGoing">
        <font-awesome-icon icon="fa-solid fa-spinner" spin size="s"
      /></move-text>
      <template #hint>
        <move-text caption secondary>
          {{ $t("name.hint") }}
        </move-text>
      </template>
    </move-input>

    <!-- ------------------------------------------------------------------------- -->
    <!-- Direzione della ferrovia -->
    <!-- ------------------------------------------------------------------------- -->
    <div class="d-flex">
      <move-text h3 bold class="mr8">{{ $t("direction.title") }}</move-text>
      <move-ballon arrow="left" width="400px">
        <template #header>
          <move-text p b class="mb8">{{ $t("direction.info.title") }}</move-text>
        </template>
        <template #content>
          <move-text p>{{ $t("direction.info.content") }}</move-text>
        </template>
      </move-ballon>
    </div>

    <move-text paragraph secondary class="mb16">{{ $t("direction.subtitle") }}</move-text>
    <div class="d-flex mb32 direction-container flex-justify-content-space-between align-items-center pb4">
      <!-- Direzione di partenza -->
      <move-input
        large
        class="input-width-medium"
        :valid="getValidationTempSectionStartDirection"
        :value="getTempSection.direction[0]"
        @input="setTempSectionStartDirection"
      >
      </move-input>
      <move-text class="px16">
        <font-awesome-icon icon="fa-solid fa-arrow-right" size="lg" />
      </move-text>

      <!-- ------------------------------------------------------------------------- -->
      <!-- Direzione di arrivo -->
      <!-- ------------------------------------------------------------------------- -->
      <move-input
        large
        class="input-width-medium"
        :valid="getValidationTempSectionEndDirection"
        :value="getTempSection.direction[1]"
        @input="setTempSectionEndDirection"
      >
      </move-input>
    </div>

    <!-- ------------------------------------------------------------------------- -->
    <!-- Descrizione -->
    <!-- ------------------------------------------------------------------------- -->
    <move-text h3 bold class="mb8">{{ $t("description.title") }}</move-text>
    <move-input
      large
      class="input-width-large mb32"
      :value="getTempSection.notes"
      @input="(value) => setTempSection({ notes: value })"
    >
    </move-input>

    <!-- ------------------------------------------------------------------------- -->
    <!-- Lunghezza della ferrovia -->
    <!-- ------------------------------------------------------------------------- -->
    <div class="d-flex mb32 direction-container flex-justify-content-space-between pb4">
      <div class="input-width-large-2">
        <move-text h3 bold class="mb8">{{ $t("sectionLength.title") }}</move-text>
        <move-input
          large
          class="mr16"
          :valid="getValidationTempSectionSectionLength"
          type="number"
          :value="getTempSection.sectionLength"
          @input="(value) => setTempSection({ sectionLength: parseFloat(value) })"
          >m
          <template #hint>
            <move-text caption secondary>
              {{ $t("sectionLength.hint") }}
            </move-text>
          </template>
        </move-input>
      </div>

      <!-- ------------------------------------------------------------------------- -->
      <!-- Lunghezza delle traversine -->
      <!-- ------------------------------------------------------------------------- -->
      <div class="input-width-large-2">
        <div class="d-flex">
          <move-text h3 bold class="mb8 mr8">{{ $t("sleepersLength.title") }}</move-text>
          <move-ballon arrow="left" width="300px">
            <template #header>
              <move-text p b class="mb8">{{ $t("sleepersLength.info.title") }}</move-text>
            </template>
            <template #content>
              <move-text p>{{ $t("sleepersLength.info.content") }}</move-text>
            </template>
          </move-ballon>
        </div>
        <move-input
          large
          type="number"
          :value="getTempSection.sleepersLength"
          :valid="getValidationTempSectionSleepersLength"
          @input="(value) => setTempSection({ sleepersLength: parseFloat(value) })"
          >m
          <template #hint>
            <move-text caption secondary>
              {{ $t("sleepersLength.hint") }}
            </move-text>
          </template>
        </move-input>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "form-step-1",
  computed: {
    ...mapGetters({
      getTempSection: "railsV2/getTempSection",
      getValidationTempSectionName: "railsV2/getValidationTempSectionName",
      getValidationTempSectionStartDirection:
        "railsV2/getValidationTempSectionStartDirection",
      getValidationTempSectionEndDirection:
        "railsV2/getValidationTempSectionEndDirection",
      getValidationTempSectionSleepersLength:
        "railsV2/getValidationTempSectionSleepersLength",
      getValidationTempSectionSectionLength:
        "railsV2/getValidationTempSectionSectionLength",
      getIsAsyncValidationOnGoing: "railsV2/getIsAsyncValidationOnGoing",
    }),
  },
  methods: {
    ...mapActions({
      setTempSectionStartDirection: "railsV2/setTempSectionStartDirection",
      setTempSectionEndDirection: "railsV2/setTempSectionEndDirection",
      setTempSection: "railsV2/setTempSection",
      getValidationSectionName: "railsV2/getValidationSectionName",
    }),
    async onChangeSectionName(value) {
      this.setTempSection({ name: value });
      await this.getValidationSectionName(value);
    },
  },
};
</script>
<style scoped>
.input-width-large {
  width: 100%;
  max-width: 800px;
  min-width: 400px;
}
.input-width-large-2 {

  width: 100%;
  min-width: 300px;
}

.direction-container {
  width: 100%;  
  max-width: 800px;
  overflow-x: auto  ;
}

.input-width-medium {
  width: 100%;
  min-width: 300px;
}

.icon {
  width: auto;
  color: white;
}
</style>
