<i18n>
  {
   "en":{
      "hint": {
        "title": "Tilt-beam Chains",
        "info": {
          "title": "Tilt-beam chains:",
          "content": {
            "1": "Tilt-beam chains are used to detect the railway’s vertical deformation:",
            "2": "the profile is shaped using the angles provided at one time by all the tiltmeters of the chain."
          }
        },
        "li": {
          "1": "Create a new chain of tilt-beam.",
          "2": "Select the sensors that belong to that chain, following the sequence of installation.",
          "3": "Set the length of every beam."
        },
        "msg": "For proper operation, Sensors used for the Rail Tool must have a 2 min cadence. For the management of operation parameters, visit the Settings page."
      },
      "beamSection":{
         "menu":{
            "rename":"Rename",
            "delete":"Delete"
         }
      },
      "tiltbeam":{
         "title":"Tilt-beam chains",
         "length":"Beam length",
         "menu":{
            "edit":"Edit",
            "delete":"Remove"
         }
      },
      "addSensorBtn":"Add a sensor",
      "addBeamSectionBtn":"Add a tilt-beam chain",
      "direction": "Direction",
      "modalDeleteBeamSection":{
         "title":"Do you want to delete the tilt-beam chain",
         "msg":"You are deleting the tilt-beam chain. If you go on, all the data collected for that chain will be lost.",
         "btnConfirmText":"Delete the chain",
         "btnCancelText":"Back"
      },
      "modalDeleteSensor":{
         "title":"Do you want to delete the sensor",
         "msg":"You are deleting the sensor. If you do it, the data collected by the sensor from now on will not be available. The previously processed data will continue to be available.",
         "btnConfirmText":"Remove the sensor",
         "btnCancelText":"Back"
      }
   },
   "it":{
      "hint": {
        "title": "Catene di tilt-beam",
        "info": {
          "title": "Catene di tilt-beam:",
          "content": {
            "1": "Le catene dei tilt-beam servono per calcolare la Deformazione verticale della linea ferroviaria:",
            "2": "La ricostruzione del profilo del binario è creata partendo dai valori di spostamento verticale in mm misurati contemporaneamente da tutti gli inclinometri che formano la catena di tilt-beam."
          }
        },
        "li": {
          "1": "Crea una catena di Tilt-beam.",
          "2": "Seleziona i sensori che fanno parte della catena di tilt-beam nel corretto ordine di installazione.",
          "3": "Imposta la lunghezza di ogni beam."
        },
        "msg": "Per un corretto funzionamento, i sensori utilizzati nel Tool Rail devono avere cadenza pari a 2 minuti. Per la gestione dei parametri di funzionamento, vai alle Impostazioni."
      },
      "beamSection":{
         "menu":{
            "rename":"Rinomina",
            "delete":"Elimina"
         }
      },
      "tiltbeam":{
         "title":"Catene di Tilt-beam",
         "length":"Lunghezza beam",
         "menu":{
            "edit":"Modifica",
            "delete":"Elimina"
         }
      },
      "addSensorBtn":"Aggiungi un sensore",
      "addBeamSectionBtn":"Aggiungi una catena",
      "direction": "Direzione",
      "modalDeleteSensor":{
         "title":"Eliminare il sensore",
         "msg":"Stai eliminando il sensore da questa Sezione di monitoraggio. Se procedi, i dati rilevati da questo momento in avanti da questo sensore non saranno più disponibili. I dati elaborati precedentemente continueranno ad essere disponibili.",
         "btnConfirmText":"Elimina Sensore",
         "btnCancelText":"Annulla"
      },
      "modalDeleteBeamSection":{
         "title":"Eliminare la catena di tilt-beam",
         "msg":"Stai eliminando una Catena di tilt-beam. Se procedi, tutti i dati rilevati fino a questo momento dai sensori appartenenti alla Catena non saranno più disponibili.",
         "btnConfirmText":"Elimina Catena",
         "btnCancelText":"Annulla"
      }
   }
}
</i18n>

<template>
  <div>
    <!-- ------------------------------------------------------------------------- -->
    <!-- RENDER BEAM SECTIONS LIST -->
    <!-- ------------------------------------------------------------------------- -->

    <div class="beam-section-list-wrapper">
      <div class="hint-container">
        <div class="d-flex">
          <move-text h3 b class="mr8"> {{ $t("hint.title") }} </move-text>
          <!-- info -->
          <move-ballon arrow="left" width="600px">
            <template #header>
              <move-text p b class="mb8">{{ $t("hint.info.title") }}</move-text>
            </template>
            <template #content>
              <move-text p class="mb16">{{ $t("hint.info.content.1") }}</move-text>
              <move-text p>{{ $t("hint.info.content.2") }}</move-text>
            </template>
          </move-ballon>
        </div>
        <ul>
          <li class="li-style">
            <move-text p secondary> {{ $t("hint.li.1") }} </move-text>
          </li>
          <li class="li-style">
            <move-text p secondary> {{ $t("hint.li.2") }} </move-text>
          </li>
          <li class="li-style">
            <move-text p secondary> {{ $t("hint.li.3") }} </move-text>
          </li>
        </ul>

        <move-text p secondary>
          {{ $t("hint.msg") }}
        </move-text>
      </div>

      <div class="beam-section-list-container">
        <!-- header -->
        <div class="header">
          <move-text p b class="title px32 py8"> {{ $t("tiltbeam.title") }} </move-text>
          <div class="selection px32 py8">
            <move-select
              :options="getTempSection.beamSections"
              label="name"
              v-model="beamSectionSelected"
            ></move-select>
            <div class="actions">
              <move-btn icon @click="onAddBeamSection">
                <font-awesome-icon icon="fa-solid fa-plus" />
              </move-btn>
              <move-menu-btn
                :disabled="!getTempSection.beamSections.length"
                :options="getBeamSectionMenuOptions"
                @rename="onRenameBeamSection(beamSectionSelected)"
                @delete="onDeleteBeamSection(beamSectionSelected)"
              >
              </move-menu-btn>
            </div>
          </div>
          <div class="d-flex align-items-center px32 py8">
            <move-text p secondary class="mr8">{{ $t("direction") }}:</move-text>
            <move-text p b>
              {{ getTempSection.direction[0] }}
              <font-awesome-icon icon="fa-solid fa-arrow-right" />
              {{ getTempSection.direction[1] }}
            </move-text>
          </div>
        </div>

        <div v-if="!getTempSection.beamSections.length" class="beams mt16">
          <move-btn secondary outlined rounded block @click="onAddBeamSection">
            <move-text b p>{{ $t("addBeamSectionBtn") }}</move-text>
          </move-btn>
        </div>
        <!-- beams list -->
        <div v-else class="beams scroll">
          <!-- beam item -->
          <div
            class="beam"
            v-for="(beam, index) in beamSectionSelected.beams"
            :key="`beam-${index}`"
          >
            <div class="beam-row">
              <move-text p> {{ index + 1 }} </move-text>
              <move-list-item class="sensor px32 mr16" :item="beam">
                <div class="col">
                  <move-sort-actions
                    :disableUp="index === 0"
                    :disableDown="index >= beamSectionSelected.beams.length - 1"
                    @up="onMoveUpSensor(index)"
                    @down="onMoveDownSensor(index)"
                  >
                  </move-sort-actions>
                </div>
                <div class="col">
                  <move-text p class="mr8">
                    {{ getShortEui(beam.device.eui) }}
                  </move-text>
                  <font-awesome-icon
                    icon="fa-solid fa-circle"
                    size="2xs"
                    :color="getColorBeamState(beam)"
                  />
                </div>
                <div class="col">
                  <move-menu-btn
                    :options="getBeamMenuOptions"
                    @edit="onEditBeamAction(beam)"
                    @delete="onDeleteBeamAction(beam)"
                  ></move-menu-btn>
                </div>
              </move-list-item>
              <div>
                <move-text p noWrap>
                  <font-awesome-icon icon="fa-solid fa-arrows-left-right-to-line" />
                  {{ $t("tiltbeam.length") }}: {{ beam.length }} m</move-text
                >
              </div>
            </div>
          </div>

          <move-btn secondary outlined rounded @click="onAddSensor" class="mt16">
            <move-text p b> {{ $t("addSensorBtn") }} </move-text>
          </move-btn>
        </div>
      </div>
    </div>

    <!-- ------------------------------------------------------------------------- -->
    <!-- MODALS -->
    <!-- ------------------------------------------------------------------------- -->

    <!-- Modal add/rename beam section -->
    <!-- ------------------------------------------------------------------------- -->

    <ModalBeamSection
      v-if="beamSectionModalForm"
      :value="tempBeamSection.name"
      :validationName="validationName"
      :validationSave="validationSave"
      @cancel="beamSectionModalForm = false"
      @save="onModalBeamSectionSave"
    >
    </ModalBeamSection>

    <!-- Modal add/update beam -->
    <!-- ------------------------------------------------------------------------- -->
    <ModalBeam
      v-if="beamModalForm"
      :sensors="getAvailableSensorsWithRailDetails"
      :value="tempBeam"
      :validationLength="modalBeamValidationLength"
      :validationSave="modalBeamValidationSave"
      @cancel="beamModalForm = false"
      @save="onModalBeamSave"
    ></ModalBeam>

    <!-- Modal delete beam section -->
    <!-- ------------------------------------------------------------------------- -->
    <ModalDelete
      v-if="deleteBeamSectionModal"
      :title="getModalBeamSectionDeleteText.title"
      :msg="getModalBeamSectionDeleteText.msg"
      :btnConfirmText="getModalBeamSectionDeleteText.btnConfirmText"
      :btnCancelText="getModalBeamSectionDeleteText.btnCancelText"
      @cancel="onCancelDeleteBeamSection"
      @confirm="onConfirmDeleteBeamSection"
      width="20vw"
    >
    </ModalDelete>

    <!-- Modal delete beam -->
    <!-- ------------------------------------------------------------------------- -->
    <ModalDelete
      v-if="deleteBeamModal"
      :title="getModalBeamDeleteText.title"
      :msg="getModalBeamDeleteText.msg"
      :btnConfirmText="getModalBeamDeleteText.btnConfirmText"
      :btnCancelText="getModalBeamDeleteText.btnCancelText"
      @cancel="onCancelDeleteBeam"
      @confirm="onConfirmDeleteBeam"
      width="20vw"
    >
    </ModalDelete>
  </div>
</template>
<script>
/**************************************************************** ****************************************************************/
/* IMPORTS */
/**************************************************************** ****************************************************************/
import _ from "lodash";
import ModalBeam from "../modals/ModalBeam.vue";
import ModalBeamSection from "../modals/ModalBeamSection.vue";
import ModalDelete from "../modals/ModalDelete.vue";
import { mapGetters, mapActions } from "vuex";
import { getShortEui, getSensorFromEui } from "../../../../../../helpers/sensors";

/**************************************************************** ****************************************************************/
/* COMPONENT */
/**************************************************************** ****************************************************************/
export default {
  name: "form-step-3",
  components: { ModalBeam, ModalBeamSection, ModalDelete },

  /**************************************************************** ****************************************************************/
  /* STATE */
  /**************************************************************** ****************************************************************/

  data() {
    return {
      //beam section
      beamSectionSelected: {
        beams: [],
        name: null,
      },
      tempBeamSection: {
        beams: [],
        name: null,
      },

      //beam
      tempBeam: {
        length: 0,
        enabled: true,
        sensor: null,
      },

      //modals
      beamModalForm: false,
      beamSectionModalForm: false,
      deleteBeamModal: false,
      deleteBeamSectionModal: false,
    };
  },

  /**************************************************************** ****************************************************************/
  /* COMPUTED */
  /**************************************************************** ****************************************************************/

  computed: {
    ...mapGetters({
      getTempSection: "railsV2/getTempSection",
      getAvailableSensorsWithRailDetails: "railsV2/getAvailableSensorsWithRailDetails",
      getAllSensorsWithRailDetails: "railsV2/getAllSensorsWithRailDetails",
    }),

    getModalBeamSectionDeleteText(){
      return {
        title: `${this.$t('modalDeleteBeamSection.title')} ${this.tempBeamSection.name}?`,
        msg: this.$t('modalDeleteBeamSection.msg'),
        btnCancelText: this.$t('modalDeleteBeamSection.btnCancelText'),
        btnConfirmText: this.$t('modalDeleteBeamSection.btnConfirmText')
      }
    },

    getModalBeamDeleteText(){
      const shortEui = this.tempBeam.sensor && this.tempBeam.sensor.shortEui ? this.tempBeam.sensor.shortEui : null 
      return {
        title: `${this.$t('modalDeleteSensor.title')} ${shortEui}?`,
        msg: this.$t('modalDeleteSensor.msg'),
        btnCancelText: this.$t('modalDeleteSensor.btnCancelText'),
        btnConfirmText: this.$t('modalDeleteSensor.btnConfirmText')
      }
    },

    getAvailableBeamSection() {
      return this.getTempSection.beamSections.length
        ? this.getTempSection.beamSections[0]
        : this.getDefaultBeamSection;
    },

    //beam section
    getBeamSectionMenuOptions() {
      return [
        { event: "rename", label: this.$t("beamSection.menu.rename") },
        { event: "delete", label: this.$t("beamSection.menu.delete") },
      ];
    },
    getDefaultBeamSection() {
      return {
        beams: [],
        name: null,
      };
    },

    //beam
    getBeamMenuOptions() {
      return [
        { event: "edit", label: this.$t("tiltbeam.menu.edit") },
        { event: "delete", label: this.$t("tiltbeam.menu.delete") },
      ];
    },
    getDefaultBeam() {
      return {
        length: 0,
        enabled: true,
        sensor: null,
      };
    },

    getIsEditingSection() {
      return this.tempBeamSection.name;
    },

    getIsEditingBeam() {
      return this.tempBeam.sensor ? this.tempBeam.sensor.eui : null;
    },
  },

  /**************************************************************** ****************************************************************/
  /* METHODS */
  /**************************************************************** ****************************************************************/

  methods: {
    ...mapActions({
      addBeamSection: "railsV2/addBeamSection",
      renameBeamSection: "railsV2/renameBeamSection",
      deleteBeamSection: "railsV2/deleteBeamSection",
      addBeamToBeamSection: "railsV2/addBeamToBeamSection",
      updateBeamFromBeamSection: "railsV2/updateBeamFromBeamSection",
      deleteBeamFromBeamSection: "railsV2/deleteBeamFromBeamSection",
      moveUpBeamFromTempSection: "railsV2/moveUpBeamFromTempSection",
      moveDownBeamFromTempSection: "railsV2/moveDownBeamFromTempSection",
    }),

    getShortEui: getShortEui,
    getSensorFromEui: getSensorFromEui,

    internalBeamToVuexBeam(beam) {
      return {
        length: beam.length,
        device: {
          eui: beam.sensor.eui,
          enabled: beam.enabled,
        },
      };
    },

    vuexBeamToInternalBeam(beam) {
      return {
        sensor: this.getSensorFromEui(this.getAllSensorsWithRailDetails, beam.device.eui),
        enabled: beam.device.enabled,
        length: beam.length,
      };
    },

    getColorBeamState(vuexBeam) {
      const beam = this.vuexBeamToInternalBeam(vuexBeam);
      const isOnline = beam.sensor.online;
      const isDisabled = !beam.enabled;
      return isDisabled ? "grey" : isOnline ? "green" : "red";
    },

    validationName(v) {
      return v && v.length > 0 && this.getValidationBeamSectionsOverlapping(v)
        ? true
        : false;
    },

    getValidationBeamSectionsOverlapping(name) {
      const beamSectionNames = this.getTempSection.beamSections.map((b) => b.name);
      beamSectionNames.push(name);
      const checkDuplicates = (items = []) => new Set(items).size !== items.length;
      return !checkDuplicates(beamSectionNames);
    },

    validationSave(v) {
      return this.validationName(v);
    },

    modalBeamValidationLength(v) {
      return v > 0;
    },

    modalBeamValidationSave(beam = { length: 0, enabled: true, sensor: null }) {
      return this.modalBeamValidationLength(beam.length) && beam.sensor !== null;
    },

    onMoveUpSensor(beamIDX) {
      const beamSectionIDX = this.findBeamSectionIDX(this.beamSectionSelected);
      this.moveUpBeamFromTempSection({ beamSectionIDX, beamIDX });
    },
    onMoveDownSensor(beamIDX) {
      const beamSectionIDX = this.findBeamSectionIDX(this.beamSectionSelected);
      this.moveDownBeamFromTempSection({ beamSectionIDX, beamIDX });
    },

    findBeamSectionIDX(beamSection) {
      return this.getTempSection.beamSections.findIndex((_beamSection) =>
        _.isEqual(_beamSection, beamSection)
      );
    },

    findBeamIDX(beamSectionIDX = -1, beam) {
      return this.getTempSection.beamSections[beamSectionIDX].beams.findIndex((_beam) =>
        _.isEqual(_beam, beam)
      );
    },

    /*  MANAGE BEAM SECTIONS */
    /****************************************************************/

    /* Button add beam section */
    onAddBeamSection() {
      this.tempBeamSection = this.getDefaultBeamSection;
      this.beamSectionModalForm = true;
    },

    /* Button rename beam section */
    onRenameBeamSection(beamSection) {
      this.tempBeamSection = beamSection;
      this.beamSectionModalForm = true;
    },

    /* Button delete beam section */
    onDeleteBeamSection(beamSection) {
      this.tempBeamSection = beamSection;
      this.deleteBeamSectionModal = true;
    },

    /* Save beam section */
    onModalBeamSectionSave(name = null) {
      if (this.getIsEditingSection) {
        const index = this.findBeamSectionIDX(this.tempBeamSection);
        this.renameBeamSection({ index, name });
      } else {
        const beam = {
          name,
          beams: [],
        };
        this.addBeamSection(beam);
        this.beamSectionSelected = beam;
      }
      this.tempBeamSection = this.getDefaultBeamSection;
      this.beamSectionModalForm = false;
    },

    onCancelDeleteBeamSection() {
      this.beamSectionSelected = this.getAvailableBeamSection;
      this.tempBeamSection = this.getAvailableBeamSection;
      this.tempBeam = this.getDefaultBeam;
      this.deleteBeamSectionModal = false;
    },

    onConfirmDeleteBeamSection() {
      const beamSectionIDX = this.findBeamSectionIDX(this.tempBeamSection);
      this.deleteBeamSection(beamSectionIDX);
      this.beamSectionSelected = this.getAvailableBeamSection;
      this.tempBeamSection = this.getAvailableBeamSection;
      this.tempBeam = this.getDefaultBeam;
      this.deleteBeamSectionModal = false;
    },

    /*  MANAGE BEAMS */
    /****************************************************************/

    /* Button add beam */
    /****************************************************************/
    onAddSensor() {
      this.tempBeam = this.getDefaultBeam;
      this.beamModalForm = true;
    },

    /* Save beam */
    onModalBeamSave(beam) {
      const beamSectionIDX = this.findBeamSectionIDX(this.beamSectionSelected);
      const vuexBeam = this.internalBeamToVuexBeam(beam);

      if (this.getIsEditingBeam) {
        const beamIDX = this.findBeamIDX(
          beamSectionIDX,
          this.internalBeamToVuexBeam(this.tempBeam)
        );
        this.updateBeamFromBeamSection({ beamSectionIDX, beamIDX, beam: vuexBeam });
      } else {
        this.addBeamToBeamSection({ beamSectionIDX, beam: vuexBeam });
      }

      this.tempBeam = this.getDefaultBeam;
      this.beamModalForm = false;
    },

    /* 3DOTS ACTION - edit beam */
    /****************************************************************/
    onEditBeamAction(vuexBeam) {
      this.tempBeam = this.vuexBeamToInternalBeam(vuexBeam);
      this.beamModalForm = true;
    },

    /* 3DOTS ACTION - delete beam */
    /****************************************************************/
    onDeleteBeamAction(vuexBeam) {
      this.tempBeam = this.vuexBeamToInternalBeam(vuexBeam);
      this.deleteBeamModal = true;
    },

    /* MODAL DELETE - cancel action */
    /****************************************************************/
    onCancelDeleteBeam() {
      this.tempBeam = this.getDefaultBeam;
      this.deleteBeamModal = false;
    },

    /* MODAL DELETE - confirm action */
    /****************************************************************/
    onConfirmDeleteBeam() {
      const beamSectionIDX = this.findBeamSectionIDX(this.beamSectionSelected);
      const beamIDX = this.findBeamIDX(beamSectionIDX, this.internalBeamToVuexBeam(this.tempBeam));
      this.deleteBeamFromBeamSection({ beamSectionIDX, beamIDX });
      this.tempBeam = this.getDefaultBeam;
      this.deleteBeamModal = false;
    },
  },
  mounted() {
    this.beamSectionSelected = this.getAvailableBeamSection;
  },
};
</script>
<style scoped>
.beam-section-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
}

.beam-section-list-container {
  display: flex;
  flex-direction: column;
  border: 2px solid #ccc;
  border-radius: 8px;
  max-width: 50vw;
  width: 40vw;
  min-width: 500px;
  height: 70vh;
  min-height: 600px;
  margin-left: auto;
  margin-right:auto
}

.beam-section-list-container .header {
  border-bottom: 1px solid #ccc;
}
.beam-section-list-container .header .title {
  border-bottom: 2px solid #ccc;
  background: var(--secondary-color-light);
  border-radius: 8px 8px 0px 0px;
}

.beam-section-list-container .selection {
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom: 1px solid #ccc;
}

.beam-section-list-container .selection .actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.beams {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px;
}

.beams .beam {
  box-sizing: border-box;
  padding-right: 32px;
  padding-left: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.beams .beam .beam-row {
  box-sizing: border-box;
  width: 100%;
  /* grid properties */
  display: grid;
  grid-template-columns: 20px 9fr minmax(200px, 3fr);
  align-items: center;
}

.beams .beam .distance-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.beams .beam .sensor {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.beams .beam .sensor .col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.li-style {
  color: white;
}

.no-tilt-beams {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.hint-container {
  max-width: 400px;
  padding-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
}
</style>
