<i18n>
{
  "en": {
    "title": "Start by creating a section.",
    "subtitle": "Create a section to view the data."
  },
  "it": {
    "title": "Inizia creando una Sezione di monitoraggio",
    "subtitle": "Configura almeno Sezione di monitoraggio per poter visualizzare il grafico."
  }
}
</i18n>
<template>
  <div>
    <move-card rounded class="text-align-center">
      <move-text class="pb4 mt64" h2 b>{{ $t("title") }}</move-text>
      <move-text class="pb64" secondary p>{{ $t("subtitle") }}</move-text>
    </move-card>
  </div>
</template>
<script>
export default {
  name: "empty-graph",
};
</script>
<style scoped></style>
