<template>
  <div>
    <RailsOverview></RailsOverview>
    <RailsGraphs></RailsGraphs>
  </div>
</template>
<script>
import RailsGraphs from "./components/home/RailsGraphs.vue"
import RailsOverview from "./components/home/RailsOverview.vue"
import { mapActions } from "vuex";
export default {
  name: "rails-home",
  components: {
    RailsGraphs,
    RailsOverview
  },
  methods: {
    ...mapActions({
      setPage: "railsV2/setPage",
    }),
  },
};
</script>
<style scoped></style>
