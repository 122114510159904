<i18n>
{
  "en": {
    "data": "Date",
    "maxValue": "Intensity"
  },
  "it": {
    "data": "Data",
    "maxValue": "Intensità"
  }
}
</i18n>
<template>
  <div class="m-container">
    <div class="header">
      <slot name="header"></slot>
    </div>
    <div class="m-content p16">
      <div class="header d-flex justify-content-space-between">
        <div class="d-flex align-items-center">
          <move-text p secondary> {{ $t("data") }} </move-text>
          <div class="d-flex flex-direction-column ml8">
            <move-btn v-if="!directionDate" small icon @click="orderUpDate" :class="{'active': orderKey === 'date'}">
              <font-awesome-icon icon="fa-solid fa-arrow-down" />
            </move-btn>
            <move-btn v-else small icon @click="orderDownDate" :class="{'active': orderKey === 'date'}">
              <font-awesome-icon icon="fa-solid fa-arrow-up" />
            </move-btn>
          </div>
        </div>
        <div>
          <div class="d-flex align-items-center">
            <move-text p secondary> {{ `${$t("maxValue")} [${unit}]` }} </move-text>
            <div class="d-flex flex-direction-column ml8">
              <move-btn v-if="!directionIntensity" small icon @click="orderUpIntensity" :class="{'active': orderKey === 'intensity'}">
                <font-awesome-icon icon="fa-solid fa-arrow-down" />
              </move-btn>
              <move-btn v-else small icon @click="orderDownIntensity" :class="{'active': orderKey === 'intensity'}">
                <font-awesome-icon icon="fa-solid fa-arrow-up" />
              </move-btn>
            </div>
          </div>
        </div>
      </div>
      <move-list-group class="items scroll" :items="getSamplesOrdered">
        <move-list-item
          v-for="(sample, index) in getSamplesOrdered"
          class="sample"
          :key="`sample-${index}`"
          :item="sample"
          :value="value"
          @input="(value) => $emit('input', value)"
        >
          <move-text p> {{ formatDate(sample.date) }} </move-text>
          <move-text p> {{ sample.intensity }} </move-text>
        </move-list-item>
      </move-list-group>
    </div>
  </div>
</template>
<script>

import _ from "lodash";
import { formatDate } from "../../../../../helpers/dates";
function orderItems(items, key, direction = false) {
  if (direction) {
    return _.reverse(_.sortBy(items, [key]));
  }
  return _.sortBy(items, [key]);
}
export default {
  name: "samples-table",
  props: {
    samples: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
    unit: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      orderKey: "date",
      direction: true,
      directionDate: true,
      directionIntensity: true
    };
  },
  computed: {
    getSamplesOrdered() {
      return orderItems(this.samples, this.orderKey, this.direction);
    },
  },
  methods: {
    formatDate: formatDate,

    orderUpDate(){
      this.orderKey = 'date';
      this.direction = true;
      this.directionDate = true;
    },
    orderDownDate(){
      this.orderKey = 'date';
      this.direction = false;
      this.directionDate = false;
    },

    orderUpIntensity(){
      this.orderKey = 'intensity';
      this.direction = true;
      this.directionIntensity = true;
    },
    orderDownIntensity(){
      this.orderKey = 'intensity';
      this.direction = false;
      this.directionIntensity = false;
    },


  },
  mounted() {
    this.samplesOrdered = this.samples;
  },
};
</script>
<style scoped>

.active{
  background-color: var(--primary-color) !important;
}
.oX{
  overflow-x: auto;
}
.m-container {
  border: 2px solid #ccc;
  border-radius: 8px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.m-container > .header {
  padding: 16px;
  border-bottom: 2px solid #ccc;
}

.m-content {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.m-content .items {
  overflow-y: auto;
}

.sample {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>
