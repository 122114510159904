<template>
  <div>
    <move-card rounded class="text-align-left no-padding">
      <move-tabs v-model="tab" :tabs="tabs" class="px32"></move-tabs>
      <empty-graph v-if="getSections.length === 0"></empty-graph>
      <div v-else class="px32 pt32">
        <CantMain v-if="tab === 'Cant'"></CantMain>
        <TwistMain v-if="tab === 'Twist'"></TwistMain>
        <DeflectionMain v-if="tab === 'Settlement'"></DeflectionMain>
      </div>
    </move-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EmptyGraph from "./graphs/EmptyGraph.vue";
import CantMain from "./graphs/CantMain.vue";
import TwistMain from "./graphs/TwistMain.vue";
import DeflectionMain from "./graphs/DeflectionMain.vue";
export default {
  name: "rails-graphs",
  components: {
    EmptyGraph,
    CantMain,
    TwistMain,
    DeflectionMain,
  },
  data() {
    return {
      tab: "Cant",
      tabs: ['Cant', 'Twist', 'Settlement']
    };
  },
  computed: {
    ...mapGetters({
      getSections: "railsV2/getSections",
    }),
  },
};
</script>
<style scoped>
.no-padding{
  padding: 0px;
}
</style>
