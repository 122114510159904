<i18n>
{
  "en": {
    "title": "Railway monitoring",
    "stats": {
      "monitoringSections": "Sections",
      "totalSections": "Total sensors",
      "totalLength": "Total length"
    },
    "sectionsBtn": "SECTIONS"
  },
  "it": {
    "title": "Monitoraggio della ferrovia",
    "stats": {
      "monitoringSections": "Sezioni di monitoraggio",
      "totalSections": "Sensori totali",
      "totalLength": "Lunghezza totale"
    },
    "sectionsBtn": "SEZIONI"
  }
}
</i18n>
<template>
  <div>
    <move-card rounded class="mb16 text-align-left">
      <!-- HEADER -->
      <div class="border-bottom">
        <move-text class="pb4 mt16" h2 bold>{{ $t("title") }}</move-text>
      </div>

      <!-- SUB HEADER  -->
      <div class="d-flex justify-content-space-between align-items-center">
        <div class="d-flex mt8 mb32 align-items-center overflow-x pb8">
          <div class="stat-item mr32">
            <move-text secondary p class="no-wrap">
              {{ $t("stats.monitoringSections") }}:
            </move-text>
            <move-text class="ml4" p b> {{ getSections.length }}</move-text>
          </div>
          <div class="stat-item mr32">
            <move-text secondary p class="no-wrap">
              {{ $t("stats.totalSections") }}:
            </move-text>
            <move-text class="ml4" p b>{{ getSensorsPlaced.length }}</move-text>
          </div>
          <div class="stat-item mr32">
            <move-text secondary p class="no-wrap">
              {{ $t("stats.totalLength") }}:
            </move-text>
            <move-text class="ml4" p b>{{ getTotalSectionLength }} m</move-text>
          </div>
        </div>

        <move-btn
          secondary
          outlined
          rounded
          large
          @click="onClickSections"
          v-if="getSections.length"
          class="ml16"
        >
          <move-text p bold>
            {{ $t("sectionsBtn") }}
          </move-text>
        </move-btn>
      </div>

      <!-- CONTENT -->

      <!-- EMPTY CONTENT -->
      <template v-if="!getSections.length">
        <empty-overview></empty-overview>
      </template>

      <!-- SECTIONS TABLE -->
      <template v-else>
        <railway-render
          class="mb16"
          :value="getSelectedSection"
          :sections="getSections"
          :customStyle="railwayStyle"
          @input="(value) => setSelectedSection(value)"
        ></railway-render>
        <railway-details
          :section="getSelectedSection"
          :sensors="getSectionSensors(getAllSensorsWithRailDetails, getSelectedSection)"
        ></railway-details>
      </template>
    </move-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EmptyOverview from "./overview/EmptyOverview.vue";
import RailwayRender from "../RailwayRender.vue";
import RailwayDetails from "../RailwayDetails.vue";
import { getSectionSensors, findSection } from "../../../../../../helpers/rail";
export default {
  name: "rails-overview",
  components: { EmptyOverview, RailwayRender, RailwayDetails },
  data() {
    return {
      railwayStyle: {
        binaryColor: "#ccc",
        sectionColor: "var(--secondary-color-light-2)",
        sectionHoverColor: "var(--secondary-color-light-2)",
        sectionClickColor: "var(--primary-color)",
        border: "2px solid var(--primary-border-color",
        sectionHeight: "100px",
        sectionWidth: "200px",
      },
    };
  },
  computed: {
    ...mapGetters({
      getSections: "railsV2/getSections",
      getSelectedSection: "railsV2/getSelectedSection",
      getSensorsPlaced: "railsV2/getSensorsPlaced",
      getTotalSectionLength: "railsV2/getTotalSectionLength",
      getAllSensorsWithRailDetails: "railsV2/getAllSensorsWithRailDetails",
    }),
  },
  methods: {
    ...mapActions({
      setPage: "railsV2/setPage",
      fetchSections: "railsV2/fetchSections",
      setSelectedSection: "railsV2/setSelectedSection",
      selectAvailableSection: "railsV2/selectAvailableSection",
      setLoading: "railsV2/setLoading",
    }),
    getSectionSensors: getSectionSensors,
    findSection: findSection,

    onClickSections() {
      this.setPage("section-resume");
    },

    chooseRightSection() {
      const section = this.findSection(this.getSections, this.getSelectedSection._id);
      if (section) {
        this.setSelectedSection(section);
      } else {
        this.selectAvailableSection();
      }
    },
  },
  async mounted() {
    this.setLoading(true);
    await this.fetchSections();
    this.chooseRightSection();
    this.setLoading(false);
  },
};
</script>
<style scoped>
.stat-item {
  display: flex;
  flex-wrap: wrap;
}
.border-bottom {
  border-bottom: 2px solid #505469;
}
.header {
  border-bottom: 2px solid #ccc;
}

.sections-container {
  overflow-x: auto;
  width: 100%;
}
</style>
