<i18n>
{
  "en": {
    "beamPolyline": {
      "title": "Vertical settlement",
      "subtitle": "Deformation profile of the track on the vertical plane, calculated for each tilt-beam chain of the Section.",
      "infoTitle": {
        "title": "Vertical settlement graph:",
        "content": {
          "1": "The graphs show the deformation profile of the track on the vertical plane. Each point of the chart corresponds to the displacement values in mm provided by each sensor that forms the Tilt-beam chain.",
          "2": "A graph is displayed for each tilt-beam chain. If a sensor does not perform data acquisition together with the other sensors, the tilt-beam chain is interrupted and therefore multiple graphs are displayed."
        }
      },
      "infoSamples":{
        "title":"List of aquisitions:",
        "content":{
          "1":"Tiltmeters provide sampling with a certain cadence. All the sensors are synchronized and sample at the same time. Every item in the list represents a certain synchronized acquisition of all the sensors belonging to that Section.",
          "2":"Depending on the amplitude of the selected Date Range, aggregation criteria can vary with the purpose of keeping the list easy to read and understand."
        }
      },
      "samples":{
        "title":"List of Acquisitions",
        "date":"Date range:"
      },
      "graph": {
        "title": "Acquisition selected:",
        "y": "Settlement",
        "x": "Distance"
      }
    }
  },
  "it": {
    "beamPolyline": {
      "title": "Vertical settlement",
      "subtitle": "Ricostruzione del profilo di deformazione del binario sul piano verticale per ogni catena di Tilt-beam.",
      "infoTitle": {
        "title": "Vertical settlement",
        "content": {
          "1": "I grafici mostrano la ricostruzione del profilo di deformazione del binario sul piano verticale. Ogni valore della linea è corrisponde al valori di spostamento in mm fornito da ogni sensore che forma la catena di Tilt-beam.",
          "2": "Per ogni catena di tilt-beam viene visualizzato un grafico. Se un sensore non effettua l’acquisizione dati insieme agli altri sensori, la catena di tilt-beam viene interrotta e quindi vengono visualizzati molteplici grafici."
        }
      },
      "infoSamples":{
        "title":"Elenco delle acquisizioni",
        "content":{
            "1":"I sensori forniscono i valori con una frequenza pari alla loro cadenza di acquisizione. Tutti i sensori sono sincronizzati e rilevano contemporaneamente. Ogni voce dell’elenco corrisponde ad una determinato acquisizione sincronizzata di tutti i sensori che appartengono alla Sezione di monitoraggio.",
            "2":"In base all’ampiezza dell’intervallo di tempo selezionato, i criteri di aggregazione dei dati possono variare con lo scopo di mantenere l’elenco delle acquisizioni fruibile."
        }
      },
      "samples":{
        "title":"Elenco delle acquisizioni",
        "date":"Intervallo di date:"
      },
      "graph": {
        "title": "Acquisizione selezionata:",
        "y": "Settlement",
        "x": "Distanza"
      }
    }
  }  
}
</i18n>
<template>
  <no-data-configured v-if="getShowNoDataConfigured"></no-data-configured>
  <no-data-received v-else-if="getShowNoDataReceived && !getIsLoading"></no-data-received>
  <div v-else>
    <div class="section deflection-over-space mb32">
      <div class="d-flex align-items-center">
        <move-text h3 b>{{ $t("beamPolyline.title") }}</move-text>
        <!-- info -->
        <move-ballon arrow="left" width="600px" class="ml8">
          <template #header>
            <move-text p b class="mb8">{{
              $t("beamPolyline.infoTitle.title")
            }}</move-text>
          </template>
          <template #content>
            <move-text p>{{ $t("beamPolyline.infoTitle.content.1") }}</move-text>
            <move-text p>{{ $t("beamPolyline.infoTitle.content.2") }}</move-text>
          </template>
        </move-ballon>
      </div>

      <move-text paragraph secondary class="mb32">{{
        $t("beamPolyline.subtitle")
      }}</move-text>
      <div class="data beam-polyline mt32">
        <div>
          <div class="d-flex align-items-center">
            <move-text p b class="mb16 ml4">{{
              $t("beamPolyline.samples.title")
            }}</move-text>
            <!-- info -->
            <move-ballon arrow="left" width="600px" class="ml8 mb16">
              <template #header>
                <move-text p b class="mb8">{{
                  $t("beamPolyline.infoSamples.title")
                }}</move-text>
              </template>
              <template #content>
                <move-text p class="mb16">{{ $t("beamPolyline.infoSamples.content.1") }}</move-text>
                <move-text p>{{ $t("beamPolyline.infoSamples.content.2") }}</move-text>
              </template>
            </move-ballon>
          </div>
          <samples-table
            unit="mm"
            :samples="getBeamPolylineEvents"
            class="table mr8"
            :value="getSelectedBeamPolylineEvent"
            @input="onSelectBeamPolylineEvent"
          >
            <template #header>
              <div class="d-flex no-wrap overflow-x">
                <move-text p> {{ $t("beamPolyline.samples.date") }} </move-text>
                <move-text p b class="ml8"
                  >{{ formatDateVerbose(getDateRange[0]) }} -
                  {{ formatDateVerbose(getDateRange[1]) }}</move-text
                >
              </div>
            </template>
          </samples-table>
        </div>

        <div class="graph-container">
          <div class="graph-title d-flex">
            <move-text p class="ml32">
              {{ $t("beamPolyline.graph.title") }}
            </move-text>

            <move-text p b class="ml8">{{
              formatDate(getSelectedBeamPolylineEvent.date)
            }}</move-text>
          </div>
          <div
            class="graph-row"
            v-for="(subSections, index) in getBeamPolylineData.subSections"
            :key="`sub-sections${index}`"
          >
            <div
              class="graph-col"
              v-for="(chain, index) in subSections.chains"
              :key="`chain${index}`"
            >
              <div class="ml32">
              </div>
              <move-graph
                :traces="getBeamPolylineGraph(chain.x, chain.y, chain.usedDevices).traces"
                :xTitle="getBeamPolylineGraph(chain.x, chain.y, chain.usedDevices).xTitle"
                :yTitle="getBeamPolylineGraph(chain.x, chain.y, chain.usedDevices).yTitle"
              ></move-graph>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SamplesTable from "../../SamplesTable.vue";
import NoDataConfigured from "./NoDataConfigured.vue";
import NoDataReceived from "./NoDataReceived.vue";
import { mapActions, mapGetters } from "vuex";
import { getShortEui, getSensorFromEui } from "../../../../../../../helpers/sensors";
import { formatDate, formatDateVerbose } from "../../../../../../../helpers/dates";
import { getBeamSectionsEUIs } from "../../../../../../../helpers/rail";
export default {
  name: "deflection-main",
  components: { SamplesTable, NoDataConfigured, NoDataReceived },
  computed: {
    ...mapGetters({
      getSelectedSection: "railsV2/getSelectedSection",
      getDateRange: "railsV2/getDateRange",
      getBeamPolylineEvents: "railsV2/getBeamPolylineEvents",
      getSelectedBeamPolylineEvent: "railsV2/getSelectedBeamPolylineEvent",
      getBeamPolylineData: "railsV2/getBeamPolylineData",
      getIsLoading: "railsV2/getIsLoading",
    }),
    getShowNoDataConfigured() {
      return this.getBeamSectionsEUIs(this.getSelectedSection.beamSections).length === 0;
    },
    getShowNoDataReceived() {
      return this.getBeamPolylineEvents.length === 0;
    },
    getBeamPolylineYtitle() {
      return `${this.$t("beamPolyline.graph.y")} - mm`;
    },
    getBeamPolylineXtitle() {
      return `${this.$t("beamPolyline.graph.x")} - m`;
    },
  },
  methods: {
    ...mapActions({
      fetchBeamPolylineEvents: "railsV2/fetchBeamPolylineEvents",
      fetchBeamPolyLineSpaceDomain: "railsV2/fetchBeamPolyLineSpaceDomain",
      fetchAllBeamPolyLineData: "railsV2/fetchAllBeamPolyLineData",
      setSelectedBeamPolylineEvent: "railsV2/setSelectedBeamPolylineEvent",
      setLoading: "railsV2/setLoading",
    }),

    getShortEui: getShortEui,
    getSensorFromEui: getSensorFromEui,
    formatDate: formatDate,
    formatDateVerbose: formatDateVerbose,
    getBeamSectionsEUIs: getBeamSectionsEUIs,

    getTracesDeflaction(x = [], y = [], usedDevices = []) {
      return [
        {
          x,
          y,
          type: "scatter",
          hovertemplate:
            "<b>EUI - %{text}</b><br><br>" +
            "%{yaxis.title.text}: %{y:.0}<br>" +
            "%{xaxis.title.text}: %{x:.0}<br>" +
            "<extra></extra>",
          text: usedDevices.map((s) => this.getShortEui(s) || ""),
        },
      ];
    },

    getBeamPolylineGraph(x = [], y = [], usedDevices = []){
      return {
        traces: this.getTracesDeflaction(x, y, usedDevices),
        yTitle: this.getBeamPolylineYtitle,
        xTitle: this.getBeamPolylineXtitle,
      }
    },
    async onSelectBeamPolylineEvent(event = null) {
      this.setSelectedBeamPolylineEvent(event);
      this.setLoading(true);
      await this.fetchBeamPolyLineSpaceDomain({ eventID: event._id });
      this.setLoading(false);
    },

    async fetchAllBeamPolylineDataWrapper() {
      await this.fetchAllBeamPolyLineData({
        groupID: this.getSelectedSection._id,
        firstDate: this.getDateRange[0],
        lastDate: this.getDateRange[1],
      });
    },
  },
  watch: {
    getDateRange: {
      handler: async function () {
        this.setLoading(true);
        await this.fetchAllBeamPolylineDataWrapper();
        this.setLoading(false);
      },
      deep: true,
    },
    getSelectedSection: {
      handler: async function () {
        this.setLoading(true);
        await this.fetchAllBeamPolylineDataWrapper();
        this.setLoading(false);
      },
      deep: true,
    },
  },

  async mounted() {
    this.setLoading(true);
    await this.fetchAllBeamPolylineDataWrapper();
    this.setLoading(false);
  },
};
</script>
<style scoped>
.data {
  display: grid;
  overflow-x: auto; 
}

.data.beam-polyline {
  grid-template-columns: minmax(200px, 400px) minmax(600px, 1fr);
  column-gap: 32px;
}

.deflection-over-space .data .table {
  height: 600px;
  max-height: 600px;
}

.graph-row{
  display: flex;
  overflow-x: auto;
}

.graph-col{
  min-width: 600px;
  width: 100%;
}

</style>
