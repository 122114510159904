<i18n>
{
  "en":{
    "hint":{
        "title":"Sensors installed on sleepers",
        "info":{
          "title":"Sensors installed on sleepers:",
          "content":{
              "1":"Tiltmeters installed on sleepers are used to calculate the Cant and Twist of the tracks:",
              "2":"The Cant is the transverse inclination of the railway expressed as the difference in height (in mm) between the two rails. This parameter is estimated using the angles provided by each Tiltmeter.",
              "3":"The Twist is the difference between two transversal levels measured separately at a predefined distance. To calculate the Twist, it is necessary to process the data collected by 2 consecutive Tiltmeters installed on the sleepers."
          }
        },
        "li":{
          "1":"Select the sensors installed on the sleepers, following the sequence of installation",
          "2":"Set the distance between the sensors"
        },
        "msg":"For proper operation, Sensors used for the Rail Tool must have a 2 min cadence. For the management of operation parameters, visit the Settings page."
    },
    "sleeperContainer":{
        "title":"Sensors installed on sleepers",
        "direction":"Direction",
        "distance":"Distance",
        "addButton":"Add a sensor",
        "modify":"Edit",
        "delete":"Remove"
    },
    "modalDeleteSensor":{
        "title":"Do you want to delete the sensor",
        "msg":"You are deleting the sensor. If you do it, the data collected by the sensor from now on will not be available. The previously processed data will continue to be available.",
        "btnConfirmText":"Remove the sensor",
        "btnCancelText":"Back"
    }
  },
  "it":{
    "hint":{
        "title":"Sensori installati sulle traversine",
        "info":{
          "title":"Sensori installati sulle traversine:",
          "content":{
              "1":"Gli inclinometri installati sulle traversine della ferrovia servono per calcolare il Cant e il Twist dei binari:",
              "2":"Il parametro Cant corrisponde all’inclinazione trasversale della ferrovia espressa come dislivello in mm tra i due binari. Questo parametro viene calcolato a partire dai valori di angolo forniti da ogni singolo inclinometro.",
              "3":"Il parametro del Twist / torsione dei binari corrisponde alla differenza algebrica tra due livelli trasversali misurati separatamente ad una distanza predefinita. Per calcolare il parametro TWIST è necessario elaborare i dati raccolti da 2 inclinometri installati uno successivo all’altro sulle traversine."
          }
        },
        "li":{
          "1":"Seleziona i sensori che sono installati sulle traversine della ferrovia nel corretto ordine di installazione.",
          "2":"Imposta la distanza tra i sensori."
        },
        "msg":"Per un corretto funzionamento, i sensori utilizzati nel Tool Rail devono avere cadenza pari a 2 minuti. Per la gestione dei parametri di funzionamento, vai alle Impostazioni."
    },
    "sleeperContainer":{
        "title":"Sensori installati sulle traversine",
        "direction":"Direzione",
        "distance":"Distanza",
        "addButton":"Aggiungi un sensore",
        "modify":"Modifica",
        "delete":"Rimuovi"
    },
    "modalDeleteSensor":{
        "title":"Eliminare il sensore",
        "msg":"Stai eliminando il sensore. Se procedi, i dati rilevati da questo momento in avanti da questo sensore non saranno più disponibili. I dati elaborati precedentemente continueranno ad essere disponibili.",
        "btnConfirmText":"Elimina Sensore",
        "btnCancelText":"Annulla"
    }
  }
}
  </i18n>
<!-- ------------------------------------------------------------------------- -->
<!-- TEMPLATE -->
<!-- ------------------------------------------------------------------------- -->
<template>
  <div>
    <div class="sleeper-list-wrapper">
      <div class="hint-container">
        <div class="d-flex">
          <move-text h3 b class="mr8"> {{ $t("hint.title") }} </move-text>
          <!-- info -->
          <move-ballon arrow="left" width="600px">
            <template #header>
              <move-text p b class="mb8">{{
                $t("hint.info.title")
              }}</move-text>
            </template>
            <template #content>
              <move-text p class="mb16">{{
                $t("hint.info.content.1")
              }}</move-text>
              <move-text p class="mb16">{{ $t("hint.info.content.2") }}</move-text>
              <move-text p>{{ $t("hint.info.content.3") }}</move-text>
            </template>
          </move-ballon>
        </div>
        <ul>
          <li class="li-style">
            <move-text p secondary> {{ $t("hint.li.1") }} </move-text>
          </li>
          <li class="li-style">
            <move-text p secondary> {{ $t("hint.li.2") }} </move-text>
          </li>
        </ul>

        <move-text p secondary>
          {{ $t("hint.msg") }}
        </move-text>
      </div>

      <div class="sleeper-list-container">
        <!-- header -->
        <div class="header px32 py8">
          <move-text p b> {{ $t("sleeperContainer.title") }} </move-text>
        </div>
        <!-- sub header -->
        <div class="subheader px32 py8">
          <div class="d-flex align-items-center p">
            <move-text p secondary class="mr8"
              >{{ $t("sleeperContainer.direction") }}:</move-text
            >
            <move-text p b>
              {{ getTempSection.direction[0] }}
              <font-awesome-icon icon="fa-solid fa-arrow-right" />
              {{ getTempSection.direction[1] }}
            </move-text>
          </div>
        </div>

        <!-- sleeper list -->
        <div class="sleepers scroll">
          <!-- sleeper item -->
          <div
            class="sleeper"
            v-for="(sleeper, index) in getTempSection.sleepers"
            :key="`sleeper-${index}`"
          >
            <!-- DISTANCE row -->
            <div class="distance-row py8" v-if="index > 0">
              <div class="col">
                <move-btn
                  icon
                  secondary
                  outlined
                  @click="onAddSensor(index)"
                  class="style-custom-btn-cirlce"
                  ><font-awesome-icon icon="fa-solid fa-plus"
                /></move-btn>
              </div>
              <div class="col">
                <move-text p>
                  <font-awesome-icon
                    icon="fa-solid fa-arrows-left-right-to-line"
                    rotation="90"
                  />
                  {{ $t("sleeperContainer.distance") }}:
                  {{ sleeper.distanceFromPrevious }} m
                </move-text>
              </div>
              <div class="col"></div>
            </div>

            <!-- SENSOR row -->
            <div class="sensor-row">
              <move-text p class="text-align-center"> {{ index + 1 }} </move-text>
              <move-list-item class="sensor px32" :item="sleeper">
                <div class="col"></div>
                <div class="col">
                  <move-text p class="mr8">
                    {{ getShortEui(sleeper.device.eui) }}
                  </move-text>
                  <font-awesome-icon
                    icon="fa-solid fa-circle"
                    size="2xs"
                    :color="getColorSleeperState(sleeper)"
                  />
                </div>
                <div class="col">
                  <move-menu-btn
                    :options="getOptions"
                    :value="sleeper"
                    @edit="onEditSensorAction(sleeper, index)"
                    @delete="onDeleteSensor(sleeper, index)"
                  ></move-menu-btn>
                </div>
              </move-list-item>
              <div class="col"></div>
            </div>
          </div>

          <!-- Add sensor button -->
          <div class="btn-add-sensor-container">
            <div></div>
            <move-btn
              secondary
              outlined
              rounded
              @click="onAddSensor(getTempSection.sleepers.length)"
              class="mt16"
            >
              <move-text p b>{{ $t("sleeperContainer.addButton") }}</move-text>
            </move-btn>
            <div></div>
          </div>
        </div>
      </div>
    </div>

    <!-- ------------------------------------------------------------------------- -->
    <!-- MODALS -->
    <!-- ------------------------------------------------------------------------- -->

    <!-- Modal add/update sleeper -->
    <!-- ------------------------------------------------------------------------- -->
    <ModalSleeper
      v-if="sleeperModalForm"
      :sensors="getAvailableSensorsWithRailDetails"
      :value="tempSleeper"
      :hideDistance="getIsFirstSensor"
      :validationDistance="validationDistance"
      :validationSave="validationSave"
      @cancel="sleeperModalForm = false"
      @save="onSaveSleeper"
    ></ModalSleeper>

    <!-- Modal delete sleeper -->
    <!-- ------------------------------------------------------------------------- -->
    <ModalDelete
      v-if="deleteSleeperModal"
      :title="`${$t('modalDeleteSensor.title')} ${tempSleeper.sensor.shortEui}?`"
      :msg="$t('modalDeleteSensor.msg')"
      :btnConfirmText="$t('modalDeleteSensor.btnConfirmText')"
      :btnCancelText="$t('modalDeleteSensor.btnCancelText')"
      @cancel="onCancelDeleteSleeper"
      @confirm="onConfirmDeleteSleeper"
      width="20vw"
    >
    </ModalDelete>
  </div>
</template>

<!-- ------------------------------------------------------------------------- -->
<!-- SCRIPT -->
<!-- ------------------------------------------------------------------------- -->
<script>
/**************************************************************** ****************************************************************/
/* IMPORTS */
/**************************************************************** ****************************************************************/
import ModalSleeper from "../modals/ModalSleeper.vue";
import ModalDelete from "../modals/ModalDelete.vue";
import { mapGetters, mapActions } from "vuex";
import { getShortEui, getSensorFromEui } from "../../../../../../helpers/sensors";

/**************************************************************** ****************************************************************/
/* COMPONENT */
/**************************************************************** ****************************************************************/
export default {
  name: "form-step-2",
  components: { ModalSleeper, ModalDelete },

  /**************************************************************** ****************************************************************/
  /* STATE */
  /**************************************************************** ****************************************************************/

  data() {
    return {
      //temp modal sleeper value
      tempSleeper: {
        distanceFromPrevious: 0,
        enabled: true,
        sensor: null,
      },
      sleeperIDX: -1,
      editingSleeper: false,

      //modals
      sleeperModalForm: false,
      deleteSleeperModal: false,
    };
  },

  /**************************************************************** ****************************************************************/
  /* COMPUTED */
  /**************************************************************** ****************************************************************/

  computed: {
    ...mapGetters({
      getTempSection: "railsV2/getTempSection",
      getAvailableSensorsWithRailDetails: "railsV2/getAvailableSensorsWithRailDetails",
      getAllSensorsWithRailDetails: "railsV2/getAllSensorsWithRailDetails",
    }),
    getIsFirstSensor() {
      return this.sleeperIDX === 0;
    },
    getOptions() {
      return [
        { event: "edit", label: this.$t('sleeperContainer.modify') },
        { event: "delete", label: this.$t('sleeperContainer.delete') },
      ];
    },
    getDefaultSleeper() {
      return {
        distanceFromPrevious: 0,
        enabled: true,
        sensor: null,
      };
    },
  },

  /**************************************************************** ****************************************************************/
  /* METHODS */
  /**************************************************************** ****************************************************************/

  methods: {
    ...mapActions({
      updateSleeperFromTempSection: "railsV2/updateSleeperFromTempSection",
      addSleeperToTempSection: "railsV2/addSleeperToTempSection",
      moveUpSleeperFromTempSection: "railsV2/moveUpSleeperFromTempSection",
      moveDownSleeperFromTempSection: "railsV2/moveDownSleeperFromTempSection",
      deleteSleeperFromTempSection: "railsV2/deleteSleeperFromTempSection",
    }),

    /*EXTERNAL functions*/
    /****************************************************************/
    getShortEui: getShortEui,
    getSensorFromEui: getSensorFromEui,

    /*STRUCTURE conversion */
    /****************************************************************/
    internalSleeperToVuexSleeper(sleeper) {
      return {
        distanceFromPrevious: sleeper.distanceFromPrevious,
        device: {
          eui: sleeper.sensor.eui,
          enabled: sleeper.enabled,
        },
      };
    },

    vuexSleeperToInternalSleeper(sleeper) {
      return {
        sensor: this.getSensorFromEui(
          this.getAllSensorsWithRailDetails,
          sleeper.device.eui
        ),
        enabled: sleeper.device.enabled,
        distanceFromPrevious: sleeper.distanceFromPrevious,
      };
    },

    getColorSleeperState(vuexSleeper) {
      const sleeper = this.vuexSleeperToInternalSleeper(vuexSleeper);
      const isOnline = sleeper.sensor.online;
      const isDisabled = !sleeper.enabled;
      return isDisabled ? "grey" : isOnline ? "green" : "red";
    },

    /*VALIDATION - distance from previous */
    /****************************************************************/
    validationDistance(v) {
      return this.getIsFirstSensor ? true : v > 0;
    },
    /*VALIDATION - save button */
    /****************************************************************/
    validationSave(value = { distanceFromPrevious: 0, enabled: true, sensor: null }) {
      return this.validationDistance(value.distanceFromPrevious) && value.sensor !== null;
    },

    /* 3DOTS Action - edit sensor */
    /****************************************************************/
    onEditSensorAction(
      vuexSleeper = {
        distanceFromPrevious: 0,
        device: {
          eui: null,
          enabled: false,
        },
      },
      index = -1
    ) {
      this.editingSleeper = true;
      this.sleeperIDX = index;
      this.tempSleeper = this.vuexSleeperToInternalSleeper(vuexSleeper);
      this.sleeperModalForm = true;
    },

    /* 3DOTS Action - delete sensor */
    /****************************************************************/
    onDeleteSensor(vuexSleeper, index) {
      this.tempSleeper = this.vuexSleeperToInternalSleeper(vuexSleeper);
      this.sleeperIDX = index;
      this.deleteSleeperModal = true;
    },

    /* Add sensor button */
    /****************************************************************/
    onAddSensor(index = -1) {
      this.sleeperIDX = index;
      this.tempSleeper = this.getDefaultSleeper;
      this.sleeperModalForm = true;
    },

    /* MODAL SLEEPER FROM - Save/Update btn  */
    /****************************************************************/
    onSaveSleeper(
      internalSleeper = { distanceFromPrevious: 0, enabled: true, sensor: null }
    ) {
      const vuexSleeper = this.internalSleeperToVuexSleeper(internalSleeper);
      const index = this.sleeperIDX;
      if (this.editingSleeper) {
        this.updateSleeperFromTempSection({ index, sleeper: vuexSleeper });
        this.editingSleeper = false;
      } else {
        this.addSleeperToTempSection({ index, sleeper: vuexSleeper });
      }
      this.restoreDefaults();
      this.sleeperModalForm = false;
    },

    /* MODAL DELETE SENSOR - Confirm  */
    /****************************************************************/
    onConfirmDeleteSleeper() {
      this.deleteSleeperFromTempSection(this.sleeperIDX);
      this.deleteSleeperModal = false;
    },

    /* MODAL DELETE SENSOR - Cancel  */
    /****************************************************************/
    onCancelDeleteSleeper() {
      this.restoreDefaults();
      this.deleteSleeperModal = false;
    },

    /* Restore default aux function */
    /****************************************************************/
    restoreDefaults() {
      this.tempSleeper = {
        distanceFromPrevious: 0,
        enabled: true,
        sensor: null,
      };
      this.sleeperIDX = -1;
    },
  },
};
</script>
<style scoped>
.sleeper-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
}
.sleeper-list-container {
  display: flex;
  flex-direction: column;
  border: 2px solid #ccc;
  border-radius: 8px;
  max-width: 50vw;
  width: 40vw;
  min-width: 500px;
  height: 70vh;
  min-height: 600px;
  margin-right:auto;
  margin-left:auto
}

.sleeper-list-container .header {
  border-bottom: 2px solid #ccc;
  background: var(--secondary-color-light);
  border-radius: 8px 8px 0px 0px;
}



.sleeper-list-container .subheader {
  border-bottom: 1px solid #ccc;
}

.sleepers {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px;
}

.sleepers .sleeper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sleepers .sleeper .sensor-row {
  box-sizing: border-box;
  width: 100%;
  /* grid properties */
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  align-items: center;
}

.btn-add-sensor-container {
  width: 100%;
  /* grid properties */
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  align-items: center;
}

.sleepers .sleeper .distance-row {
  display: grid;
  grid-template-columns: 40px 1fr 40px;
}

.sleepers .sleeper .distance-row .col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sleepers .sleeper .sensor {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  align-items: center;
  cursor: default;
}

.sleepers .sleeper .sensor .col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.li-style {
  color: white;
}

.distance-row .col .style-custom-btn-cirlce {
  border: none;
  background: #3e4254;
}

.distance-row .col .style-custom-btn-cirlce:hover {
  background: #6e7389;
}

.hint-container {
  max-width: 400px;
  padding-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
}
</style>
