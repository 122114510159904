<template>
  <div>
    <div class="d-flex justify-content-space-between">
        <move-text b>{{ getFirstDirection }}</move-text>
        <move-text b> {{ getLastDirection }}</move-text>
      </div>
    <div class="m-container">
      <div class="binary-container">
        <div
          class="binary up"
          :style="{ background: getStyleBinaryColor, border: getStyleBorder }"
        ></div>
        <div
          class="binary down"
          :style="{ background: getStyleBinaryColor, border: getStyleBorder }"
        ></div>
      </div>
      <div class="d-flex sections-container">
        <div
          v-for="(section, index) in sections"
          :key="`section-${index}`"
          class="section"
          :style="{
            background: getSectionColor({ index, section }),
            border: getStyleBorder,
            width: customStyle.sectionWidth,
            height: customStyle.sectionHeight,
            minWidth: customStyle.sectionWidth,
            maxWidth: customStyle.sectionWidth,
            minHeight: customStyle.sectionHeight,
          }"
          @click="onSectionClick({ index, section })"
          @mouseover="sectionHoverIDX = index"
          @mouseleave="sectionHoverIDX = -1"
        >
          <move-text>{{ section.name }}</move-text>
        </div>
        <div
          v-if="!sections.length"
          :style="{
            width: customStyle.sectionWidth,
            height: customStyle.sectionHeight,
            minWidth: customStyle.sectionWidth,
            maxWidth: customStyle.sectionWidth,
            minHeight: customStyle.sectionHeight,
          }"
        >
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "sections-table",
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
    customStyle: {
      type: Object,
      default: () => ({
        binaryColor: "#ccc",
        sectionColor: "transparent",
        sectionHoverColor: "transparent",
        sectionClickColor: "transparent",
        border: "1px solid #fff",
        sectionHeight: "200px",
        sectionWidth: "200px"
      }),
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sectionHoverIDX: -1,
      sectionClickIDX: -1,
    };
  },
  computed: {
    getStyleBinaryColor() {
      return this.customStyle.binaryColor || "transparent";
    },
    getStyleSectionColor() {
      return this.customStyle.sectionColor || "transparent";
    },
    getStyleSectionHoverColor() {
      return this.customStyle.sectionHoverColor || "transparent";
    },
    getStyleSectionClickColor() {
      return this.customStyle.sectionClickColor || "transparent";
    },
    getStyleBorder() {
      return this.customStyle.border || "1px solid #fff";
    },
    getFirstDirection(){
      return this.sections.length ? this.sections[0].direction[0] : null
    },
    getLastDirection(){
      return this.sections.length ? this.sections[this.sections.length - 1].direction[1] : null
    }
  },
  methods: {
    getSectionColor(payload = { index: -1, section: null }) {
      const equals = _.isEqual(this.value, payload.section);
      if (equals) {
        return this.getStyleSectionClickColor;
      }
      if (this.sectionHoverIDX === payload.index) {
        return this.getStyleSectionHoverColor;
      }
      return this.getStyleSectionColor;
    },
    onSectionClick(payload = { index: -1, section: null }) {
      this.sectionClickIDX = payload.index;
      this.$emit("input", payload.section);
      this.$emit("click", payload);
    },
  },
};
</script>
<style scoped>
.m-container {
  position: relative;
}
.section {
  margin: 0px 8px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  text-align: center;
}

.section:hover {
  cursor: pointer;
}

.binary {
  width: 100%;
  height: 10px;
  position: absolute;
  border-radius: 16px;
}

.binary-container {
  z-index: 1;
}

.sections-container {
  position: relative;
  z-index: 2;
  overflow-x: auto;
  padding-bottom: 4px;
}

.up {
  top: 16px;
}

.down {
  bottom: 16px;
}
</style>
