<i18n>
{
  "en":{
    "title":"Configure the Rail tool to start monitoring",
    "subtitle":"Create one or more sections to better manage, analyze and understand the data.",
    "addSectionBtn":"Create a section"
  },
  "it":{
    "title":"Configura il Tool Rail per poter iniziare il monitoraggio.",
    "subtitle":"Crea una o più sezioni di monitoraggio per gestire, analizzare e comprendere meglio i dati.",
    "addSectionBtn":"Crea una Sezione"
  }
}
</i18n>
<template>
  <move-card rounded class="mb16 text-align-center">
    <move-text class="pb4 mt16" h2 bold
      >{{$t('title')}}</move-text
    >
    <move-text class="pb64" secondary paragraph
      >{{$t('subtitle')}}</move-text
    >
    <move-btn primary rounded outlined class="mb32" @click="onClickAddNewSection">
      <move-text bold paragraph>{{$t('addSectionBtn')}}</move-text>
    </move-btn>
  </move-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "empty-overview",
  methods: {
    ...mapActions({
      setPage: "railsV2/setPage",
      resetTempSection: "railsV2/resetTempSection",
    }),
    onClickAddNewSection(){
      this.resetTempSection();
      this.setPage('section-form')
    }
  },
};
</script>
<style scoped></style>
