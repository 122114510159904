<i18n>
{
  "en": {
    "title": "Manage your section",
    "subtitle": "Follow the configuration steps and fill in information about the Section, which sensors belong to it, their position, and sequence. These setting options only apply to the rail tool.",
    "next": "Next",
    "previous": "Back",
    "save": "Save"
  
  },
  "it": {
    "title": "Gestisci Sezione di monitoraggio",
    "subtitle": "Segui gli step di configurazione e indica le informazioni di questa Sezione, quali sensori vi appartengono, la loro posizione ed il relativo ordine. Queste impostazioni si applicano solo al Tool Railway.",
    "next": "Avanti",
    "previous": "Indietro",
    "save": "Salva"
  }
}
</i18n>
<template>
  <move-card rounded class="text-align-left m-container">
    <div class="d-flex align-items-center">
      <move-btn icon class="mr8" @click="$emit('back')"
        ><font-awesome-icon
          icon="fa-solid fa-arrow-left"
          size="xl"
      /></move-btn>
      <move-text h2 bold>{{ $t("title") }}</move-text>
    </div>
    <move-text paragraph secondary class="divider pb16">{{ $t("subtitle") }} </move-text>
    <slot />
    <div class="footer">
      <div class="col prev">
        <move-btn
          v-if="!hidePrevStep"
          :disabled="disablePrevStep"
          secondary
          rounded
          outlined
          large
          @click="$emit('prevStep')"
        >
          <move-text bold h3>
            <font-awesome-icon icon="fa-solid fa-arrow-left" />
            {{ $t("previous") }}
          </move-text>
        </move-btn>
      </div>
      <div class="col steps">
        <move-step :step="step" :steps="steps"></move-step>
      </div>
      <div class="col next">
        <move-btn
          v-if="isMaxStep"
          primary
          rounded
          outlined
          large
          :disabled="disableSave"
          @click="$emit('save')"
        >
          <move-text bold h3>{{ $t("save") }}</move-text>
        </move-btn>
        <move-btn
          class="btn"
          v-else
          primary
          rounded
          outlined
          large
          :disabled="disableNextStep"
          @click="$emit('nextStep')"
        >
          <move-text bold h3
            >{{ $t("next") }}
            <font-awesome-icon icon="fa-solid fa-arrow-right" />
          </move-text>
        </move-btn>
      </div>
    </div>
  </move-card>
</template>
<script>
export default {
  name: "form-template",
  props: {
    step: {
      type: Number,
      default: 5,
    },
    steps: {
      type: Number,
      default: 6,
    },
    isMinStep: {
      type: Boolean,
      default: false,
    },
    isMaxStep: {
      type: Boolean,
      default: false,
    },

    //disable actions
    disableNextStep: {
      type: Boolean,
      default: false,
    },
    disablePrevStep: {
      type: Boolean,
      default: false,
    },
    disableSave: {
      type: Boolean,
      default: false,
    },

    //show actions
    hidePrevStep: {
      type: Boolean,
      default: false,
    },
    hideNextStep: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.divider{
  border-bottom: 2px solid #505469;
}

.col.prev{
  justify-content: flex-start;
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.col.next{
  justify-content: flex-end;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
