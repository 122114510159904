<i18n>
{
   "en":{
      "cantSpace":{
         "title":"Cant along the tracks",
         "subtitle":"Select an acquisition from the list to view the graph of the Cant trend along the tracks.",
         "infoTitle":{
            "title":"Cant along the tracks:",
            "content":{
               "1":"The CANT is the transversal inclination of the railway expressed as the height difference in mm between the two rails. The CANT is estimated using the angles provided by the Tiltmeters installed on the sleepers.",
               "2":"The graph shows the Cant trend along the length of the track at a given moment. Each column of the graph corresponds to the Cant provided by a single sensor. Cant is provided simultaneously by all the sensors belonging to the monitoring section."
            }
         },
         "samples": {
            "title":"List of Acquisitions",
            "date":"Date range:"
          },
         "infoSamples":{
            "title":"List of aquisitions:",
            "content":{
              "1":"Tiltmeters provide sampling with a certain cadence. All the sensors are synchronized and sample at the same time. Every item in the list represents a certain synchronized acquisition of all the sensors belonging to that Section.",
              "2":"Depending on the amplitude of the selected Date Range, aggregation criteria can vary with the purpose of keeping the list easy to read and understand."
            }
          },
         "graph":{
            "title":"Acquisition selected:",
            "y":"Cant",
            "x":"Distance"
         }
      },
      "cantTime":{
         "title":"Cant over time",
         "subtitle":"Cant trend over time of the selected sensor.",
         "infoTitle":{
            "title":"Twist over time:",
            "content":"The graph shows the twist over time for a selected couple of consecutive sensors."
         },
         "graph":{
            "date":"Date range:",
            "select":"Selected Tiltmeter:",
            "y":"Cant",
            "x":"Time"
         }
      },
      "temperature":{
         "title":"Temperature trend",
         "subtitle":"The graph shows temperature over time for a selected sensor.",
         "infoTitle":{
            "title":"Temperature trend:",
            "content":"The graph shows temperature over time for a selected couple of consecutive sensors. Each line represents the trend of a single sensor."
         },
         "graph":{
            "y":"Temperature",
            "x":"Time"
         }
      }
   },
   "it":{
      "cantSpace":{
         "title":"Cant lungo il tracciato",
         "subtitle":"Andamento del Cant lungo la linea ferroviaria.",
         "samples":{
            "title":"Elenco delle acquisizioni",
            "date":"Intervallo di date:"
         },
         "infoTitle":{
            "title":"Cant nello spazio",
            "content":{
               "1":"Il parametro Cant corrisponde all’inclinazione trasversale della ferrovia espressa come dislivello in mm tra i due binari. Il parametro CANT viene calcolato a partire dai valori di angolo forniti dagli inclinometri installati sulle traversine.",
               "2":"Il grafico mostra l’andamento del Cant lungo la lunghezza del binario ad un determinato momento. Ogni colonna del grafico corrisponde al valore di Cant fornito da un singolo sensore. I valori di Cant sono forniti contemporaneamente da tutti i sensori appartenenti alla Sezione di monitoraggio."
            }
         },
         "infoSamples":{
            "title":"Elenco delle acquisizioni",
            "content":{
               "1":"I sensori forniscono i valori con una frequenza pari alla loro cadenza di acquisizione. Tutti i sensori sono sincronizzati e rilevano contemporaneamente. Ogni voce dell’elenco corrisponde ad una determinato acquisizione sincronizzata di tutti i sensori che appartengono alla Sezione di monitoraggio.",
               "2":"In base all’ampiezza dell’intervallo di tempo selezionato, i criteri di aggregazione dei dati possono variare con lo scopo di mantenere l’elenco delle acquisizioni fruibile."
            }
         },
         "graph":{
            "title":"Acquisizione selezionata:",
            "y":"Cant",
            "x":"Distance"
         }
      },
      "cantTime":{
         "title":"Cant nel tempo",
         "subtitle":"Valore del Cant nel tempo dello specifico Tiltmeter selezionato.",
         "infoTitle":{
            "title":"Cant nel tempo",
            "content":"Il grafico mostra l’andamento nel tempo del Cant relativo al singolo sensore selezionato."
         },
         "graph":{
            "date":"Intervallo di date:",
            "select":"Tiltmeter selezionato:",
            "y":"Cant",
            "x":"Tempo"
         }
      },
      "temperature":{
         "title":"Temperatura nel tempo",
         "subtitle":"Valori di temperature nel tempo dello specifico Tiltmeter selezionato.",
         "infoTitle":{
            "title":"Temperature nel tempo",
            "content":"Il grafico mostra l’andamento nel tempo della temperatura relativa al singolo sensore selezionato."
         },
         "graph":{
            "y":"Temperature",
            "x":"Tempo"
          }
      }
   }
}

</i18n>

<template>
  <no-data-configured v-if="getShowNoDataConfigured"></no-data-configured>
  <no-data-received v-else-if="getShowNoDataReceived && !getIsLoading"></no-data-received>
  <div v-else>
    <!-- CANT SPACE DOMAIN -->
    <!-- ----------------------------------------------------------------------------------------------- -->
    <div class="cant-space-domain mb32 ">
      <div class="d-flex align-items-center">
        <move-text h3 class="mr8" b>{{ $t("cantSpace.title") }}</move-text>
        <!-- info title -->
        <move-ballon arrow="left" width="600px">
          <template #header>
            <move-text p b class="mb8">{{ $t("cantSpace.infoTitle.title") }}</move-text>
          </template>
          <template #content>
            <move-text p class="mb16">{{
              $t("cantSpace.infoTitle.content.1")
            }}</move-text>
            <move-text p>{{ $t("cantSpace.infoTitle.content.2") }}</move-text>
          </template>
        </move-ballon>
      </div>

      <move-text p secondary>{{ $t("cantSpace.subtitle") }}</move-text>

      <div class="data cant-space-domain mt32 scroll2">
        <div>
          <div class="d-flex align-items-center">
            <move-text p b class="mb16 ml4">{{
              $t("cantSpace.samples.title")
            }}</move-text>
            <!-- info samples -->
            <move-ballon arrow="left" width="600px" class="mb16 ml8">
              <template #header>
                <move-text p b class="mb8">{{
                  $t("cantSpace.infoSamples.title")
                }}</move-text>
              </template>
              <template #content>
                <move-text p class="mb16">{{
                  $t("cantSpace.infoSamples.content.1")
                }}</move-text>
                <move-text p>{{ $t("cantSpace.infoSamples.content.2") }}</move-text>
              </template>
            </move-ballon>
          </div>

          <samples-table
            class="table"
            :samples="getCantEvents"
            :value="getSelectedCantEvent"
            unit="mm"
            @input="onChangeCantEvent"
          >
            <template #header>
              <div class="d-flex no-wrap overflow-x">
                <move-text p> {{ $t("cantSpace.samples.date") }} </move-text>
                <move-text p b class="ml16"
                  >{{ formatDateVerbose(getDateRange[0]) }} -
                  {{ formatDateVerbose(getDateRange[1]) }}</move-text
                >
              </div>
            </template>
          </samples-table>
        </div>

        <div class="graph">
          <div class="d-flex">
            <move-text p class="ml32"> {{ $t("cantSpace.graph.title") }} </move-text>
            <move-text p b class="ml8">{{
              formatDate(getSelectedCantEvent.date)
            }}</move-text>
          </div>
          <move-graph
            :traces="getCantSpaceDomainGraph.traces"
            :yTitle="getCantSpaceDomainGraph.yTitle"
            :xTitle="getCantSpaceDomainGraph.xTitle"
            :yRange="getCantRangeSpaceDomain"
          ></move-graph>
        </div>
      </div>
    </div>

    <!-- CANT TIME DOMAIN -->
    <!-- ----------------------------------------------------------------------------------------------- -->
    <div class="cant-time-domain mb32">
      <div class="d-flex align-items-center">
        <move-text h3 class="mr8">{{ $t("cantTime.title") }}</move-text>
        <!-- info -->
        <move-ballon arrow="left" width="600px">
          <template #header>
            <move-text p b class="mb8">{{ $t("cantTime.infoTitle.title") }}</move-text>
          </template>
          <template #content>
            <move-text p>{{ $t("cantTime.infoTitle.content") }}</move-text>
          </template>
        </move-ballon>
      </div>
      <move-text p secondary class="mb32">{{ $t("cantTime.subtitle") }}</move-text>
      <div class="d-flex">
        <move-text p> {{ $t("cantTime.graph.date") }} </move-text>
        <move-text p b class="ml8"
          >{{ formatDateVerbose(getDateRange[0]) }} -
          {{ formatDateVerbose(getDateRange[1]) }}</move-text
        >
      </div>
      <div class="d-flex align-items-center">
        <move-text p class="mr8"> {{ $t("cantTime.graph.select") }} </move-text>
        <move-select
          :options="getCantSensors"
          label="shortEui"
          :value="getSelectedCantSensor"
          @input="onChangeSensor"
          class="w200"
        ></move-select>
      </div>

      <div class="data cant-time-domain scroll2">
        <div class="graph">
          <move-graph
            :traces="getCantTimeDomainGraph.traces"
            :yTitle="getCantTimeDomainGraph.yTitle"
            :xTitle="getCantTimeDomainGraph.xTitle"
            :yRange="getCantRangeTimeDomain"
          ></move-graph>
        </div>
      </div>
    </div>

    <!-- TEMPERATURES -->
    <!-- ----------------------------------------------------------------------------------------------- -->
    <div class="pb32">
      <div class="d-flex align-items-center">
        <move-text h3 class="mr8">{{ $t("temperature.title") }}</move-text>
        <!-- info -->
        <move-ballon arrow="left" width="600px">
          <template #header>
            <move-text p b class="mb8">{{ $t("temperature.infoTitle.title") }}</move-text>
          </template>
          <template #content>
            <move-text p>{{ $t("temperature.infoTitle.content") }}</move-text>
          </template>
        </move-ballon>
      </div>

      <move-text p secondary>{{ $t("temperature.subtitle") }}</move-text>
      <div class="data temperatures scroll2">
        <div class="graph">
          <move-graph
            :traces="getTemperatureGraph.traces"
            :yTitle="getTemperatureGraph.yTitle"
            :xTitle="getTemperatureGraph.xTitle"
          >
          </move-graph>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SamplesTable from "../../SamplesTable.vue";
import NoDataConfigured from "./NoDataConfigured.vue";
import NoDataReceived from "./NoDataReceived.vue";
import { mapGetters, mapActions } from "vuex";
import { getShortEui, getSensorFromEui } from "../../../../../../../helpers/sensors";
import { formatDate, formatDateVerbose } from "../../../../../../../helpers/dates";
import { getSleepersEUIs } from "../../../../../../../helpers/rail";
export default {
  name: "cant-main",
  components: { SamplesTable, NoDataConfigured, NoDataReceived },

  /**************************************************************** ****************************************************************/
  /* COMPUTED */
  /**************************************************************** ****************************************************************/

  computed: {
    ...mapGetters({
      getCantEvents: "railsV2/getCantEvents",
      getSelectedCantEvent: "railsV2/getSelectedCantEvent",
      getSelectedTwistEvent: "railsV2/getSelectedTwistEvent",
      getCantSpace: "railsV2/getCantSpace",
      getCantRangeSpaceDomain: "railsV2/getCantRangeSpaceDomain",
      getCantRangeTimeDomain: "railsV2/getCantRangeTimeDomain",
      getSelectedSection: "railsV2/getSelectedSection",
      getDateRange: "railsV2/getDateRange",
      getCantTimeDomain: "railsV2/getCantTimeDomain",
      getTemperatures: "railsV2/getTemperatures",
      getSelectedCantEUI: "railsV2/getSelectedCantEUI",
      getAllSensorsWithRailDetails: "railsV2/getAllSensorsWithRailDetails",
      getIsLoading: "railsV2/getIsLoading",
    }),

    getShowNoDataConfigured() {
      return this.getSleepersEUIs(this.getSelectedSection.sleepers).length === 0;
    },
    getShowNoDataReceived() {
      return this.getCantEvents.length === 0;
    },

    getCantSensors() {
      return this.getAllSensorsWithRailDetails.filter((s) =>
        this.getCantSpace.usedDevices.includes(s.eui)
      );
    },
    getSelectedCantSensor() {
      return this.getSensorFromEui(
        this.getAllSensorsWithRailDetails,
        this.getSelectedCantEUI
      );
    },

    /* Graph cant, space domain  */
    /****************************************************************/
    getCantSpaceDomainGraph() {
      return {
        traces: this.getTracesSpaceDomain,
        yTitle: this.getCantSpaceGraphYtitle,
        xTitle: this.getCantSpaceGraphXtitle,
      };
    },
    getTracesSpaceDomain() {
      return [
        {
          x: this.getCantSpace.x,
          y: this.getCantSpace.y,
          type: "bar",
          mode: "markers",
          hovertemplate:
            "<b>EUI - %{text}</b><br><br>" +
            "%{yaxis.title.text}: %{y:.0}<br>" +
            "%{xaxis.title.text}: %{x:.0}<br>" +
            "<extra></extra>",
          text: this.getCantSpace.usedDevices.map((s) => this.getShortEui(s)),
        },
      ];
    },
    getCantSpaceGraphYtitle() {
      return `${this.$t("cantSpace.graph.y")} - mm`;
    },
    getCantSpaceGraphXtitle() {
      return `${this.$t("cantSpace.graph.x")} - m`;
    },

    /* Graph traces of cant, time domain  */
    /****************************************************************/
    getCantTimeDomainGraph() {
      return {
        traces: this.getTracesTimeDomain,
        yTitle: this.getCantTimeGraphYtitle,
        xTitle: this.getCantTimeGraphXtitle,
      };
    },
    getTracesTimeDomain() {
      return [
        {
          x: this.getCantTimeDomain.x,
          y: this.getCantTimeDomain.y,
          type: "scatter",
        },
      ];
    },
    getCantTimeGraphYtitle() {
      return `${this.$t("cantTime.graph.y")} - mm`;
    },
    getCantTimeGraphXtitle() {
      return `${this.$t("cantTime.graph.x")}`;
    },

    /* Graph traces of temperatures  */
    /****************************************************************/
    getTemperatureGraph() {
      return {
        traces: this.getTracesTemperatures,
        yTitle: this.getTemperatureGraphYtitle,
        xTitle: this.getTemperatureGraphXtitle,
      };
    },

    getTracesTemperatures() {
      return [
        {
          x: this.getTemperatures.dates,
          y: this.getTemperatures.temperature,
          type: "scatter",
        },
      ];
    },

    getTemperatureGraphYtitle() {
      return `${this.$t("temperature.graph.y")} - C°`;
    },

    getTemperatureGraphXtitle() {
      return `${this.$t("temperature.graph.x")}`;
    },
  },

  /**************************************************************** ****************************************************************/
  /* METHODS */
  /**************************************************************** ****************************************************************/

  methods: {
    ...mapActions({
      setCantSample: "railsV2/setCantSample",
      setSelectedCantEvent: "railsV2/setSelectedCantEvent",
      setSelectedTwistEvent: "railsV2/setSelectedTwistEvent",
      fetchCantSpaceDomainData: "railsV2/fetchCantSpaceDomainData",
      setSelectedCantEUI: "railsV2/setSelectedCantEUI",
      fetchCantTimeDomainData: "railsV2/fetchCantTimeDomainData",
      fetchCantTemperatureData: "railsV2/fetchCantTemperatureData",
      fetchAllCantData: "railsV2/fetchAllCantData",
      setLoading: "railsV2/setLoading",
    }),

    getShortEui: getShortEui,
    getSensorFromEui: getSensorFromEui,
    formatDate: formatDate,
    formatDateVerbose: formatDateVerbose,
    getSleepersEUIs: getSleepersEUIs,

    /****************************************************************/
    /* FETCH DATA WRAPPERS */
    /****************************************************************/

    /* Fetch all data */
    /****************************************************************/
    async fetchAllCantDataWrapper() {
      await this.fetchAllCantData({
        groupID: this.getSelectedSection._id,
        firstDate: this.getDateRange[0],
        lastDate: this.getDateRange[1],
      });
    },

    /* Fetch Cant - Time Domain */
    /****************************************************************/
    async fetchCantTimeDomainDataWrapper(eui) {
      await this.fetchCantTimeDomainData({
        groupID: this.getSelectedSection._id,
        eui,
        firstDate: this.getDateRange[0],
        lastDate: this.getDateRange[1],
      });
    },

    /* Fetch Cant - Space Domain */
    /****************************************************************/
    async fetchCantSpaceDomainDataWrapper(cantEvent) {
      await this.fetchCantSpaceDomainData({ eventID: cantEvent._id });
    },

    /* Fetch Temperature */
    /****************************************************************/
    async fetchCantTemperatureDataWrapper(eui) {
      await this.fetchCantTemperatureData({
        eui,
        firstDate: this.getDateRange[0],
        lastDate: this.getDateRange[1],
      });
    },

    /****************************************************************/
    /* COMPONENT ACTIONS */
    /****************************************************************/

    /* On change EUI */
    /****************************************************************/
    async onChangeSensor(sensor) {
      this.setLoading(true);
      this.setSelectedCantEUI(sensor.eui);
      await this.fetchCantTimeDomainDataWrapper(sensor.eui);
      await this.fetchCantTemperatureDataWrapper(sensor.eui);
      this.setLoading(false);
    },

    /* On change cant event */
    /****************************************************************/
    async onChangeCantEvent(cantEvent) {
      this.setLoading(true);
      this.setSelectedCantEvent(cantEvent);
      await this.fetchCantSpaceDomainDataWrapper(cantEvent);
      this.setLoading(false);
    },
  },

  /**************************************************************** ****************************************************************/
  /* WATCHER */
  /**************************************************************** ****************************************************************/

  watch: {
    getDateRange: {
      handler: async function () {
        this.setLoading(true);
        await this.fetchAllCantDataWrapper();
        this.setLoading(false);
      },
      deep: true,
    },
    getSelectedSection: {
      handler: async function () {
        this.setLoading(true);
        await this.fetchAllCantDataWrapper();
        this.setLoading(false);
      },
      deep: true,
    },
  },

  /**************************************************************** ****************************************************************/
  /* LIFE CYCLE METHODS */
  /**************************************************************** ****************************************************************/

  async mounted() {
    this.setLoading(true);
    await this.fetchAllCantDataWrapper();
    this.setLoading(false);
  },
};
</script>
<style scoped>
.data{
  display: grid;
  overflow-x: auto; 
}
.data.cant-space-domain {
  grid-template-columns: minmax(300px, 400px) minmax(600px, 1fr);
  column-gap: 32px;
}

.data.cant-time-domain {
  grid-template-columns: minmax(800px, 1fr);
}

.data.temperatures {
  grid-template-columns: minmax(800px, 1fr);
}

.cant-space-domain .data .table {
  height: 600px;
  max-height: 600px;

}


</style>
