<i18n>
{
  "en": {},
  "it": {}
}
</i18n>
<template>
  <div>
    <PageTemplate :hideTips="true">
      <template v-slot:nav>
        <DateRangeSelect
          :initDateRange="getDateRange"
          :selectedStructure="getStructureDetails"
          :availableStructures="getStructures"
          @structure-selected="selectStructure"
          @date-selected="updateDateRange"
        />
      </template>
      <template v-slot:content>
        <Rails/>
      </template>
    </PageTemplate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PageTemplate from "../PageTemplate.vue";
import DateRangeSelect from "../../components/ui/DRSelect.vue";
import Rails from "../../components/modules/railsV2/Rails.vue"

export default {
  name: "railsview",
  components: {
    PageTemplate,
    DateRangeSelect,
    Rails
  },
  computed: {
    ...mapGetters({
      getStructures: "structure/getStructures",
      getSelectedStructure: "structure/getSelectedStructure",
      getStructureDetails: "structure/getStructureDetails",
      getDateRange: "railsV2/getDateRange",  
      getSelectedSection: "railsV2/getSelectedSection",
    })
  },
  methods: {
    ...mapActions({
      fetchStructures: "structure/fetchStructures",
      selectStructure: "structure/selectStructure",
      setDateRange: "railsV2/setDateRange",
    }),
    updateDateRange(dateRange = []){
      this.setDateRange(dateRange)
    }
  },


  async mounted() {
    await this.fetchStructures();
    if (!this.getSelectedStructure) {
      await this.selectStructure(this.getStructures[0]);
    }
  }
};
</script>
<style scoped>
.m-content{
  padding: 0px 16px;
}
</style>
