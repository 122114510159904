<i18n>
{
  "en":{
    "title":"Add sensor on the sleepers",
    "status":{
        "title":"Sensor status",
        "online":"Online",
        "offline":"Offline"
    },
    "selection":{
        "title":"Add a sensor:",
        "placeholder":"-"
    },
    "distance":"Set the distance from the previous sensor",
    "enabled":"Enabled",
    "disabled":"Disabled",
    "btnCancel":"Back",
    "btnSave":"Save"
  },
  "it":{
    "title":"Aggiungi sensore sulle traversine",
    "selection":{
        "title":"Seleziona un sensore:",
        "placeholder":"-"
    },
    "status":{
        "title":"Stato sensore",
        "online":"Online",
        "offline":"Offline"
    },
    "distance":"Inserisci la distanza dal sensore precedente",
    "enabled":"Abilitato",
    "disabled":"Disabilitato",
    "btnCancel":"Annulla",
    "btnSave":"Salva"
  }
}
</i18n>

<template>
    <move-modal class="m-container">
      <!-- HEADER -->
      <!-- ------------------------------------------------------------------------- -->
      <template #header :style="{width: width}">
        <move-text h2 class="mb32">{{ $t("title") }}</move-text>
      </template>

      <!-- CONTENT -->
      <!-- ------------------------------------------------------------------------- -->
      <div class="mb32" :style="{width: width}">
        <!-- Sensor selection -->
        <move-text p bold>{{ $t('selection.title') }}</move-text>
        <move-select
          :placeholder="$t('selection.placeholder')"
          class="mb8"
          :options="sensors"
          label="shortEui"
          v-model="sleeper.sensor"
        ></move-select>

        <!-- Sensor state -->
        <div class="d-flex align-items-center mb16">
          <move-text p>{{ $t('status.title') }}</move-text>
          <font-awesome-icon
            class="ml16"
            v-if="sleeper.sensor"
            icon="fa-solid fa-circle"
            size="2xs"
            :color="getColorSensorStatus(sleeper.sensor)"
          />
          <move-text p class="ml4">{{
            sleeper.sensor ? (sleeper.sensor.online ? $t('status.online') : $t('status.offline')) : "-"
          }}</move-text>
        </div>

        <!-- Distance from previous -->
        <div v-if="!hideDistance">
          <move-text p>{{ $t('distance') }}</move-text>
          <move-input
            type="number"
            large
            class="mb16"
            v-model.number="sleeper.distanceFromPrevious"
            :valid="getValidationDistance"
            >m</move-input
          >
        </div>

        <!-- Sensor disabled -->
        <div class="d-flex">
          <move-text class="mr8">{{ $t('enabled') }}</move-text>
          <move-switch v-model="sleeper.enabled"></move-switch>
        </div>
      </div>

      <!-- FOOTER -->
      <!-- ------------------------------------------------------------------------- -->
      <template #footer :style="{width: width}">
        <div class="text-align-right">
          <!-- Button cancel -->
          <move-btn class="mr8" secondary rounded outlined @click="$emit('cancel')">
            <move-text p bold>{{ $t('btnCancel') }}</move-text>
          </move-btn>
          <!-- Button save -->
          <move-btn
            :disabled="!getValidationSave"
            primary
            rounded
            outlined
            @click="$emit('save', sleeper)"
          >
            <move-text p bold>{{ $t('btnSave') }}</move-text>
          </move-btn>
        </div>
      </template>
    </move-modal>
</template>
<script>
/**************************************************************** ****************************************************************/
/* IMPORTS */
/**************************************************************** ****************************************************************/
import _ from "lodash";
/**************************************************************** ****************************************************************/
/* COMPONENT */
/**************************************************************** ****************************************************************/
export default {
  name: "modal-sleeper",

  /**************************************************************** ****************************************************************/
  /* PROPS */
  /**************************************************************** ****************************************************************/
  props: {
    sensors: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({
        distanceFromPrevious: 0,
        enabled: true,
        sensor: null,
      }),
    },
    validationDistance: {
      type: Function,
      default: () => {
        return true;
      },
    },
    validationSave: {
      type: Function,
      default: () => {
        return true;
      },
    },
    hideDistance: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "100%"
    },
  },

  /**************************************************************** ****************************************************************/
  /* STATE */
  /**************************************************************** ****************************************************************/
  data() {
    return {
      sleeper: _.cloneDeep(this.value),
    };
  },

  /**************************************************************** ****************************************************************/
  /* COMPUTED */
  /**************************************************************** ****************************************************************/

  computed: {
    getValidationDistance() {
      return this.validationDistance(this.sleeper.distanceFromPrevious);
    },
    getValidationSave() {
      return this.validationSave(this.sleeper);
    },
  },

  methods: {
    getColorSensorStatus(sensor) {
      return sensor && sensor.online ? "green" : "red";
    },
  },
};
</script>
<style scoped>
</style>
